import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { useReactToPrint } from "react-to-print";
import { Print } from "@mui/icons-material";
import {
  Br,
  Cut,
  Line,
  Printer,
  Text,
  Row,
  render,
} from "react-thermal-printer";
import { useLocation } from "react-router";

const TAX_RATE = 0.0;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

// function createRow(desc, qty, unit) {
//   const price = priceRow(qty, unit);
//   return { desc, qty, unit, price };
// }

// function subtotal(items) {
//   return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
// }

// const rows = [
//   createRow("Panner Butter Masala", 2, 300),
//   createRow("Butter Tandoori Roti", 10, 20),
//   createRow("Dal Tadka", 2, 280),
//   createRow("Masala Papad", 2, 40),
// ];

function PosBillgenerator() {
  const componentref = useRef();
  const handleprint = useReactToPrint({
    content: () => componentref.current,
  });
  const [billdata, setbilldata] = useState([]);
  const location = useLocation();
  // const invoiceSubtotal = subtotal(billdata.items);
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  useEffect(() => {
    setbilldata(location.state);
    localStorage.removeItem("PosCart");
  }, [location]);
  const [price1, setPrice1] = useState(0);

  // const { data } = location.state || {};
  // console.log(billdata);
  const handlePrice = () => {
    let sum = 0;
    billdata.map((item) => {
      let total = parseInt(item.price * item.quantity);
      sum += total;
      setPrice1(sum);
      return sum;
    });
  };
  useEffect(() => {
    handlePrice();
  }, [billdata]);
  // const receipt = (
  //   <Printer type="epson" width={42} characterSet="korea">
  //     <Text size={{ width: 2, height: 2 }}>9,500원</Text>
  //     <Text bold={true}>결제 완료</Text>
  //     <Br />
  //     <Line />
  //     <Row left="결제방법" right="체크카드" />
  //     <Row left="카드번호" right="123456**********" />
  //     <Row left="할부기간" right="일시불" />
  //     <Row left="결제금액" right="9,500" />
  //     <Row left="부가세액" right="863" />
  //     <Row left="공급가액" right="8,637" />
  //     <Line />
  //     <Row left="맛있는 옥수수수염차 X 2" right="11,000" />
  //     <Text>옵션1(500)/옵션2/메모</Text>
  //     <Row left="(-) 할인" right="- 500" />
  //     <Br />
  //     <Line />
  //     <Row left="합계" right="9,500" />
  //     <Row left="(-) 할인 2%" right="- 1,000" />
  //     <Line />
  //     <Row left="대표" right="김대표" />
  //     <Row left="사업자등록번호" right="000-00-00000" />
  //     <Row left="대표번호" right="0000-0000" />
  //     <Row left="주소" right="어디시 어디구 어디동 몇동몇호" />
  //     <Line />
  //     <Br />
  //     <Text align="center">Wifi: some-wifi / PW: 123123</Text>
  //     <Cut />
  //   </Printer>
  // );

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          position: "fixed",
          top: 100,
          right: 50,
          zIndex: 6,
        }}
      >
        <Button color="primary" onClick={handleprint} variant="contained">
          <Print />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{ p: 3, backgroundColor: "#fff", width: "295px" }}
          ref={componentref}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="https://w7.pngwing.com/pngs/149/967/png-transparent-marriott-logo-marriott-international-hotel-logo-company-accommodation-hotel-text-business-resort-thumbnail.png"
              alt=""
              height={100}
              width={100}
              style={{
                mixBlendMode: "multiply",
                filter: "contrast(2)",
                objectFit: "contain",
              }}
            />
          </div>
          <Typography variant="body1">
            Hotel Marriott <br />
            address:-Address
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Typography variant="h3">INVOICE</Typography>
          </div>
          <Divider />
          <Typography variant="body1">INVOICE NO:-728378</Typography>
          <Typography variant="body1">Table No :&nbsp;POS</Typography>

          <Divider />
          <TableContainer sx={{ mt: 3, overflow: "hidden" }}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Details
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Qty.</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billdata.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
                      <br />
                      {row.price}&nbsp;Rs
                      <br />
                      <Typography fontWeight={600}>Total</Typography>
                      {/* {ccyFormat(row.price)} */}
                      {row.price * row.quantity}&nbsp;Rs
                    </TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell>Total Quantity</TableCell>
                  <TableCell align="right">
                    {billdata.total_items}
                  </TableCell>
                  <TableCell />
                </TableRow> */}
                <TableRow>
                  <TableCell />
                  <TableCell>Subtotal</TableCell>
                  <TableCell align="right">{price1}&nbsp;Rs</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                    0
                  )} %`}</TableCell>
                  <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography fontWeight={700}>Total</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={700}>
                      {/* {ccyFormat(invoiceTotal)} */}
                      {price1}&nbsp;Rs
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Typography variant="h5">Thank You and Visit Again...</Typography>
          </div>
          <Divider />
        </Box>
      </div>

      {/* <div ref={componentref}>{receipt}</div> */}
    </div>
  );
}

export default PosBillgenerator;
