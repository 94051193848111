import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { Typography } from "@mui/material";
function RecentOrderRequest() {
  const [data, setdata] = useState([
    "Panner",
    "Salads",
    "Item 3",
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 1",
    "Item 2",
    "Item 3",
  ]);

  return (
    <div>
      <Typography align="center" variant="h4">Order From Table No :</Typography>
      <List
        sx={{
          width: 300,
          mt:2.9,
          bgcolor: "background.paper",
          maxHeight: 340,
          overflow: "auto",
          scrollbarWidth: "thin",
        }}
      >
        {data.map((item, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar src="" />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant="h5">{item}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default RecentOrderRequest;
