import React, { Suspense, lazy } from "react";
// import SidebarLayout from "./layouts/SidebarLayout";
import KitchenSidebarLayout from "./Kitchenlayouts/SidebarLayout";
// import BaseLayout from "./layouts/BaseLayout";
import { Navigate } from "react-router-dom";
import Socket from "./Socket";
const KitchenRoutes = [
  {
    path: "",
    element: <KitchenSidebarLayout />,
    children: [
      {
        path: "/kitchen/dashboard",
        element: <Socket/>,
      },
      {
        path: "overview",
        element: <Navigate to="/" replace />,
      },
      {
        path: "login",
        element: <h1>login</h1>,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <h1>404</h1>,
          },
          {
            path: "500",
            element: <h1>440499</h1>,
          },
          {
            path: "maintenance",
            element: <h1>mannn</h1>,
          },
          {
            path: "coming-soon",
            element: <h1>soon</h1>,
          },
        ],
      },
      {
        path: "*",
        element: <h1>not found404</h1>,
      },
    ],
  },
  // {
  //   path: "dashboards",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="tasks" replace />,
  //     },
  //     {
  //       path: "crypto",
  //       element: <Crypto />,
  //     },
  //     {
  //       path: "messenger",
  //       element: <Messenger />,
  //     },
  //   ],
  // },
  // {
  //   path: "management",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="transactions" replace />,
  //     },
  //     {
  //       path: "transactions",
  //       element: <Transactions />,
  //     },
  //     {
  //       path: "profile",
  //       children: [
  //         {
  //           path: "",
  //           element: <Navigate to="details" replace />,
  //         },
  //         {
  //           path: "details",
  //           element: <UserProfile />,
  //         },
  //         {
  //           path: "settings",
  //           element: <UserSettings />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: "/components",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="buttons" replace />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "modals",
  //       element: <Modals />,
  //     },
  //     {
  //       path: "accordions",
  //       element: <Accordions />,
  //     },
  //     {
  //       path: "tabs",
  //       element: <Tabs />,
  //     },
  //     {
  //       path: "badges",
  //       element: <Badges />,
  //     },
  //     {
  //       path: "tooltips",
  //       element: <Tooltips />,
  //     },
  //     {
  //       path: "avatars",
  //       element: <Avatars />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "forms",
  //       element: <Forms />,
  //     },
  //   ],
  // },
];

export default KitchenRoutes;
