import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  BarChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from "recharts";

const dataset = [
  {
    week1: 59,
    week2: 57,
    week3: 86,
    week4: 21,
    month: "Jan",
  },
  {
    week1: 50,
    week2: 52,
    week3: 78,
    week4: 28,
    month: "Feb",
  },
  {
    week1: 47,
    week2: 53,
    week3: 106,
    week4: 41,
    month: "Mar",
  },
  {
    week1: 54,
    week2: 56,
    week3: 92,
    week4: 73,
    month: "Apr",
  },
  {
    week1: 57,
    week2: 69,
    week3: 92,
    week4: 99,
    month: "May",
  },
  {
    week1: 60,
    week2: 63,
    week3: 103,
    week4: 144,
    month: "June",
  },
  {
    week1: 59,
    week2: 60,
    week3: 105,
    week4: 319,
    month: "July",
  },
  {
    week1: 65,
    week2: 60,
    week3: 106,
    week4: 249,
    month: "Aug",
  },
  {
    week1: 51,
    week2: 51,
    week3: 95,
    week4: 131,
    month: "Sept",
  },
  {
    week1: 60,
    week2: 65,
    week3: 97,
    week4: 55,
    month: "Oct",
  },
  {
    week1: 67,
    week2: 64,
    week3: 76,
    week4: 48,
    month: "Nov",
  },
  {
    week1: 61,
    week2: 70,
    week3: 103,
    week4: 25,
    month: "Dec",
  },
];
// console.log(dataset);
// const valueFormatter = (value) => `${value}mm`;

export default function BarGraph1() {
  const [value, setValue] = React.useState("Jan");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  var result = dataset.filter((x) => x.month === value);
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <FormControl sx={{ m: 1,ml:1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Month</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={value}
          label="Month"
          onChange={handleChange}
        >
          {dataset.map((item, index) => (
            <MenuItem key={index} value={item.month}>
              {item.month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
     
      <BarChart width={smallscreen ? 380 : 730} height={332} data={result}>
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip cursor={false} />
        <Legend />
        <Bar dataKey="week1" fill="#426ff5" />
        <Bar dataKey="week2" fill="#b9c3df" />
        <Bar dataKey="week3" fill="#8c96b5" />
        <Bar dataKey="week4" fill="#bfbdc1" />
      </BarChart>
    </>
  );
}
