import { useContext } from "react";
import Scrollbar from "../../../../components/Scrollbar";
import { SidebarContext } from "../../../../contexts/SidebarContext";

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Typography,
} from "@mui/material";

import SidebarMenu from "./SidebarMenu";
import Logo from "../../../../components/LogoSign";
import { useAuth } from '../../../../AuthContext/AuthContext';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const {logout}=useAuth()

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
          background:
            theme.palette.mode === "dark"
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === "dark" ? theme.sidebar.boxShadow : "none",
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52,
              }}
            >
              {/* <Logo /> */}
              <svg
                fill="#f6f4f4"
                viewBox="0 -3.5 31 31"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="0.62"
                >
                  <path d="m28.895 24h-26.511c-1.313-.009-2.375-1.071-2.384-2.383v-14.878c.009-1.313 1.071-2.375 2.383-2.384h26.512c1.313.009 2.375 1.071 2.384 2.383v14.881c0 1.315-1.066 2.381-2.381 2.381-.001 0-.002 0-.003 0zm-26.511-18.055c-.002 0-.005 0-.007 0-.435 0-.787.352-.787.787v.008 14.88.007c0 .435.352.787.787.787h.008 26.511.007c.435 0 .787-.352.787-.787 0-.003 0-.005 0-.008v-14.88c0-.002 0-.005 0-.007 0-.435-.352-.787-.787-.787-.003 0-.005 0-.008 0z"></path>
                  <path d="m22.665 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-12.333c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 12.333c.466.021.838.393.858.857v.002z"></path>
                  <path d="m25.653 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m8.233 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m29.818 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-2.129c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.466.021.838.393.858.857v.002z"></path>
                  <path d="m5.277 1.907v.004c0 .472-.383.854-.854.854h-2.102c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.458.023.821.397.826.858z"></path>
                  <path d="m5.658 14.558v2h1.939v-2h1.176v5.214h-1.176v-2.194h-1.939v2.194h-1.176v-5.214z"></path>
                  <path d="m14.591 17.103c.009.078.013.168.013.259 0 1.385-1.123 2.508-2.508 2.508-.028 0-.057 0-.085-.001h.004c-.009 0-.019 0-.029 0-1.343 0-2.431-1.088-2.431-2.431 0-.084.004-.168.013-.25l-.001.01c-.006-.064-.009-.137-.009-.212 0-1.394 1.13-2.523 2.523-2.523h.031-.002c.018 0 .04-.001.061-.001 1.341 0 2.427 1.087 2.427 2.427 0 .075-.003.15-.01.223l.001-.009zm-3.751.063c0 1.018.48 1.748 1.271 1.748s1.24-.763 1.24-1.78c0-.954-.445-1.748-1.271-1.748-.794-.002-1.24.762-1.24 1.778z"></path>
                  <path d="m16.18 15.544h-1.398v-.986h4.006v.986h-1.43v4.228h-1.176z"></path>
                  <path d="m22.506 17.546h-1.907v1.24h2.16v.954h-3.368v-5.182h3.21v.954h-2.034v1.081h1.907v.954z"></path>
                  <path d="m23.555 14.558h1.176v4.228h2.066v.986h-3.242z"></path>
                  <path d="m15.64 11.222-1.717.925.32-1.907-1.369-1.373 1.907-.286.858-1.714.858 1.717 1.907.286-1.367 1.367.32 1.907z"></path>
                  <path d="m21.584 11.443-1.303.699.254-1.462-1.05-1.017 1.43-.19.667-1.335.636 1.335 1.462.19-1.049 1.018.222 1.462z"></path>
                  <path d="m25.366 11.698-.858.445.16-.954-.699-.667.954-.16.445-.858.414.858.954.16-.667.667.16.954z"></path>
                  <path d="m9.727 11.443 1.271.699-.254-1.462 1.049-1.018-1.43-.19-.635-1.334-.667 1.335-1.431.19 1.049 1.018-.254 1.462z"></path>
                  <path d="m5.913 11.698.858.445-.16-.954.699-.667-.954-.16-.444-.858-.414.858-.954.16.699.667-.16.954z"></path>
                </g>
                <g id="SVGRepo_iconCarrier">
                  <path d="m28.895 24h-26.511c-1.313-.009-2.375-1.071-2.384-2.383v-14.878c.009-1.313 1.071-2.375 2.383-2.384h26.512c1.313.009 2.375 1.071 2.384 2.383v14.881c0 1.315-1.066 2.381-2.381 2.381-.001 0-.002 0-.003 0zm-26.511-18.055c-.002 0-.005 0-.007 0-.435 0-.787.352-.787.787v.008 14.88.007c0 .435.352.787.787.787h.008 26.511.007c.435 0 .787-.352.787-.787 0-.003 0-.005 0-.008v-14.88c0-.002 0-.005 0-.007 0-.435-.352-.787-.787-.787-.003 0-.005 0-.008 0z"></path>
                  <path d="m22.665 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-12.333c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 12.333c.466.021.838.393.858.857v.002z"></path>
                  <path d="m25.653 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m8.233 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m29.818 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-2.129c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.466.021.838.393.858.857v.002z"></path>
                  <path d="m5.277 1.907v.004c0 .472-.383.854-.854.854h-2.102c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.458.023.821.397.826.858z"></path>
                  <path d="m5.658 14.558v2h1.939v-2h1.176v5.214h-1.176v-2.194h-1.939v2.194h-1.176v-5.214z"></path>
                  <path d="m14.591 17.103c.009.078.013.168.013.259 0 1.385-1.123 2.508-2.508 2.508-.028 0-.057 0-.085-.001h.004c-.009 0-.019 0-.029 0-1.343 0-2.431-1.088-2.431-2.431 0-.084.004-.168.013-.25l-.001.01c-.006-.064-.009-.137-.009-.212 0-1.394 1.13-2.523 2.523-2.523h.031-.002c.018 0 .04-.001.061-.001 1.341 0 2.427 1.087 2.427 2.427 0 .075-.003.15-.01.223l.001-.009zm-3.751.063c0 1.018.48 1.748 1.271 1.748s1.24-.763 1.24-1.78c0-.954-.445-1.748-1.271-1.748-.794-.002-1.24.762-1.24 1.778z"></path>
                  <path d="m16.18 15.544h-1.398v-.986h4.006v.986h-1.43v4.228h-1.176z"></path>
                  <path d="m22.506 17.546h-1.907v1.24h2.16v.954h-3.368v-5.182h3.21v.954h-2.034v1.081h1.907v.954z"></path>
                  <path d="m23.555 14.558h1.176v4.228h2.066v.986h-3.242z"></path>
                  <path d="m15.64 11.222-1.717.925.32-1.907-1.369-1.373 1.907-.286.858-1.714.858 1.717 1.907.286-1.367 1.367.32 1.907z"></path>
                  <path d="m21.584 11.443-1.303.699.254-1.462-1.05-1.017 1.43-.19.667-1.335.636 1.335 1.462.19-1.049 1.018.222 1.462z"></path>
                  <path d="m25.366 11.698-.858.445.16-.954-.699-.667.954-.16.445-.858.414.858.954.16-.667.667.16.954z"></path>
                  <path d="m9.727 11.443 1.271.699-.254-1.462 1.049-1.018-1.43-.19-.635-1.334-.667 1.335-1.431.19 1.049 1.018-.254 1.462z"></path>
                  <path d="m5.913 11.698.858.445-.16-.954.699-.667-.954-.16-.444-.858-.414.858-.954.16.699.667-.16.954z"></path>
                </g>
              </svg>
              <Typography variant="h5">DigiMenu</Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <Box p={2}>
          <Button
            onClick={logout}
            variant="contained"
            color="error"
            size="small"
            fullWidth
          >
           Logout
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                 {/* <Logo /> */}
              <svg
                fill="#f6f4f4"
                viewBox="0 -3.5 31 31"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="0.62"
                >
                  <path d="m28.895 24h-26.511c-1.313-.009-2.375-1.071-2.384-2.383v-14.878c.009-1.313 1.071-2.375 2.383-2.384h26.512c1.313.009 2.375 1.071 2.384 2.383v14.881c0 1.315-1.066 2.381-2.381 2.381-.001 0-.002 0-.003 0zm-26.511-18.055c-.002 0-.005 0-.007 0-.435 0-.787.352-.787.787v.008 14.88.007c0 .435.352.787.787.787h.008 26.511.007c.435 0 .787-.352.787-.787 0-.003 0-.005 0-.008v-14.88c0-.002 0-.005 0-.007 0-.435-.352-.787-.787-.787-.003 0-.005 0-.008 0z"></path>
                  <path d="m22.665 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-12.333c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 12.333c.466.021.838.393.858.857v.002z"></path>
                  <path d="m25.653 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m8.233 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m29.818 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-2.129c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.466.021.838.393.858.857v.002z"></path>
                  <path d="m5.277 1.907v.004c0 .472-.383.854-.854.854h-2.102c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.458.023.821.397.826.858z"></path>
                  <path d="m5.658 14.558v2h1.939v-2h1.176v5.214h-1.176v-2.194h-1.939v2.194h-1.176v-5.214z"></path>
                  <path d="m14.591 17.103c.009.078.013.168.013.259 0 1.385-1.123 2.508-2.508 2.508-.028 0-.057 0-.085-.001h.004c-.009 0-.019 0-.029 0-1.343 0-2.431-1.088-2.431-2.431 0-.084.004-.168.013-.25l-.001.01c-.006-.064-.009-.137-.009-.212 0-1.394 1.13-2.523 2.523-2.523h.031-.002c.018 0 .04-.001.061-.001 1.341 0 2.427 1.087 2.427 2.427 0 .075-.003.15-.01.223l.001-.009zm-3.751.063c0 1.018.48 1.748 1.271 1.748s1.24-.763 1.24-1.78c0-.954-.445-1.748-1.271-1.748-.794-.002-1.24.762-1.24 1.778z"></path>
                  <path d="m16.18 15.544h-1.398v-.986h4.006v.986h-1.43v4.228h-1.176z"></path>
                  <path d="m22.506 17.546h-1.907v1.24h2.16v.954h-3.368v-5.182h3.21v.954h-2.034v1.081h1.907v.954z"></path>
                  <path d="m23.555 14.558h1.176v4.228h2.066v.986h-3.242z"></path>
                  <path d="m15.64 11.222-1.717.925.32-1.907-1.369-1.373 1.907-.286.858-1.714.858 1.717 1.907.286-1.367 1.367.32 1.907z"></path>
                  <path d="m21.584 11.443-1.303.699.254-1.462-1.05-1.017 1.43-.19.667-1.335.636 1.335 1.462.19-1.049 1.018.222 1.462z"></path>
                  <path d="m25.366 11.698-.858.445.16-.954-.699-.667.954-.16.445-.858.414.858.954.16-.667.667.16.954z"></path>
                  <path d="m9.727 11.443 1.271.699-.254-1.462 1.049-1.018-1.43-.19-.635-1.334-.667 1.335-1.431.19 1.049 1.018-.254 1.462z"></path>
                  <path d="m5.913 11.698.858.445-.16-.954.699-.667-.954-.16-.444-.858-.414.858-.954.16.699.667-.16.954z"></path>
                </g>
                <g id="SVGRepo_iconCarrier">
                  <path d="m28.895 24h-26.511c-1.313-.009-2.375-1.071-2.384-2.383v-14.878c.009-1.313 1.071-2.375 2.383-2.384h26.512c1.313.009 2.375 1.071 2.384 2.383v14.881c0 1.315-1.066 2.381-2.381 2.381-.001 0-.002 0-.003 0zm-26.511-18.055c-.002 0-.005 0-.007 0-.435 0-.787.352-.787.787v.008 14.88.007c0 .435.352.787.787.787h.008 26.511.007c.435 0 .787-.352.787-.787 0-.003 0-.005 0-.008v-14.88c0-.002 0-.005 0-.007 0-.435-.352-.787-.787-.787-.003 0-.005 0-.008 0z"></path>
                  <path d="m22.665 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-12.333c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 12.333c.466.021.838.393.858.857v.002z"></path>
                  <path d="m25.653 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m8.233 2.543v.014c0 .712-.577 1.289-1.289 1.289-.005 0-.01 0-.015 0h.001c-.004 0-.009 0-.014 0-.712 0-1.289-.577-1.289-1.289 0-.005 0-.01 0-.015v.001-1.24c0-.004 0-.009 0-.014 0-.712.577-1.289 1.289-1.289h.015-.001.014c.712 0 1.289.577 1.289 1.289v.015-.001z"></path>
                  <path d="m29.818 1.907v.004c0 .472-.383.854-.854.854-.001 0-.003 0-.004 0h-2.129c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.466.021.838.393.858.857v.002z"></path>
                  <path d="m5.277 1.907v.004c0 .472-.383.854-.854.854h-2.102c-.001 0-.003 0-.004 0-.472 0-.854-.383-.854-.854 0-.001 0-.003 0-.004 0-.001 0-.003 0-.004 0-.472.383-.854.854-.854h.004 2.129c.458.023.821.397.826.858z"></path>
                  <path d="m5.658 14.558v2h1.939v-2h1.176v5.214h-1.176v-2.194h-1.939v2.194h-1.176v-5.214z"></path>
                  <path d="m14.591 17.103c.009.078.013.168.013.259 0 1.385-1.123 2.508-2.508 2.508-.028 0-.057 0-.085-.001h.004c-.009 0-.019 0-.029 0-1.343 0-2.431-1.088-2.431-2.431 0-.084.004-.168.013-.25l-.001.01c-.006-.064-.009-.137-.009-.212 0-1.394 1.13-2.523 2.523-2.523h.031-.002c.018 0 .04-.001.061-.001 1.341 0 2.427 1.087 2.427 2.427 0 .075-.003.15-.01.223l.001-.009zm-3.751.063c0 1.018.48 1.748 1.271 1.748s1.24-.763 1.24-1.78c0-.954-.445-1.748-1.271-1.748-.794-.002-1.24.762-1.24 1.778z"></path>
                  <path d="m16.18 15.544h-1.398v-.986h4.006v.986h-1.43v4.228h-1.176z"></path>
                  <path d="m22.506 17.546h-1.907v1.24h2.16v.954h-3.368v-5.182h3.21v.954h-2.034v1.081h1.907v.954z"></path>
                  <path d="m23.555 14.558h1.176v4.228h2.066v.986h-3.242z"></path>
                  <path d="m15.64 11.222-1.717.925.32-1.907-1.369-1.373 1.907-.286.858-1.714.858 1.717 1.907.286-1.367 1.367.32 1.907z"></path>
                  <path d="m21.584 11.443-1.303.699.254-1.462-1.05-1.017 1.43-.19.667-1.335.636 1.335 1.462.19-1.049 1.018.222 1.462z"></path>
                  <path d="m25.366 11.698-.858.445.16-.954-.699-.667.954-.16.445-.858.414.858.954.16-.667.667.16.954z"></path>
                  <path d="m9.727 11.443 1.271.699-.254-1.462 1.049-1.018-1.43-.19-.635-1.334-.667 1.335-1.431.19 1.049 1.018-.254 1.462z"></path>
                  <path d="m5.913 11.698.858.445-.16-.954.699-.667-.954-.16-.444-.858-.414.858-.954.16.699.667-.16.954z"></path>
                </g>
              </svg>
              <Typography variant="h5">DigiMenu</Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <Box p={2}>
          <Button
            onClick={logout}
            variant="contained"
            color="error"
            size="small"
            fullWidth
          >
           Logout
          </Button>
        </Box>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
