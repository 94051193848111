import React from "react";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CountUp from "react-countup";
import { TrendingUp } from "@mui/icons-material";
function TrendingItems() {
  const Data = [
    { title: "Panner", price: 20, order: 200 },
    { title: "Kaju Masala", price: 20, order: 400 },
    { title: "Butter Khichdi", price: 20, order: 2600 },
    { title: "Palak Panner", price: 20, order: 230 },
  ];
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          p: 1,
          transform: "scale(1)",
          transition: " all ease-in 0.2s",
          "&:hover": {
            // color: "red",
            transform: "scale(1.05)",
            filter: `drop-shadow(8px 8px 10px black)`,
          },
        }}
        justifyContent="center"
      >
        {Data.map((item, index) => (
          <Grid key={index} item xs={6} md={3} lg={3}>
            <Card
              sx={{
                p: 2,
                height: 300,
                transform: "scale(0.9)",
                transition: " all ease-in 0.2s",
                "&:hover": {
                  // color: "red",
                  transform: "scale(1)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },
              }}
            >
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  sx={{ fontSize: "21px" }}
                  component="div"
                >
                  {item.title}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="h5" color="text.secondary">
                    <CountUp end={item.order} />+ Orders in this month
                  </Typography>
                  <TrendingUp sx={{ color: "green" }} />
                </div>
                {/* <Typography color="green">{item.price}$</Typography> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TrendingItems;
