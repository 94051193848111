import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";

function AddNewCategory() {
  const [category, setcategory] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const demo = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/CategoriesList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setcategory(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [Dialogopen, setDialogOpen] = React.useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [deleteName, setDeletename] = useState("");
  const [search, setSearch] = useState("");

  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteid("");
    setDeletename("");
  };
  useEffect(() => {
    demo();
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setEditIndex(null);
    setName("");
    setImage("");
    setPreviewImage("");
  };
  // console.log(image)
  // console.log(editIndex);
  const filterItems = category.filter((item) =>
    item?.name?.toUpperCase().includes(search?.toUpperCase())
  );
  const { enqueueSnackbar } = useSnackbar();

  // console.log(filterItems)
  const handleAddItem = () => {
    if (editMode) {
      try {
        let data = new FormData();
        data.append("images[]", image);
        data.append("name", name);
        // const data = {
        //   name: name,
        // };
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/updateCategories/${editIndex}`,
          headers: {
            Authorization: `Bearer ${token} `,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            enqueueSnackbar(JSON.stringify(response.data), {
              variant: "success",
            });
            demo();
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {
        
        enqueueSnackbar("Error updating item:", {
          variant: "error",
        });
        console.error("Error updating item:", error);
      }
    } else {
      try {
        let data = new FormData();
        data.append("images[]", image);
        data.append("name", name);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/addCategories",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            enqueueSnackbar(JSON.stringify(response.data), {
              variant: "success",
            })
            demo();
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {
        enqueueSnackbar("Error updating item:", {
          variant: "error",
        });
        console.error("Error adding item:", error);
      }
    }
    handleCloseModal();
  };

  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(filterItems[index].name);
    // setImage(filterItems[index].images);
    setPreviewImage(
      `https://simplesoft.co.in/qrmenuapp/uploads/cat/${id}/${filterItems[index].img}`
    );

    // setPreviewImage(filterItems[index].image);
    setOpenModal(true);
  };

  const handleDeleteItem = () => {
    try {
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/deleteCategories/${Deleteid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          enqueueSnackbar(JSON.stringify(response.data), {
            variant: "success",
          })
          demo();
          handleDialogClose();

          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      enqueueSnackbar("Error updating item:", {
        variant: "error",
      });
      console.error("Error deleting item:", error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // console.log(image)
  // const theme = useTheme();
  // const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [search]);
  return (
    <>
     
      <TextField
        sx={{
          "& fieldset": { borderRadius: 20 },
          position: "sticky",
          top: 89,
          bgcolor: "white",
          borderRadius: 20,
          zIndex: 5,
        }}
        fullWidth
        placeholder="Search here..."
        label="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <Clear
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSearch("")}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      {filterItems.length > 0 && search && (
        <Typography variant="h4" sx={{ mt: 3, ml: 1 }}>
          {filterItems.length} &nbsp;
          {filterItems.length > 1 ? "Categories" : "Category"} found by this
          Keyword "{search}".
        </Typography>
      )}
      {filterItems.length > 0 ? (
        <Grid container spacing={2} sx={{ p: 1, mb: 7, mt: 1 }}>
          {filterItems.map((item, index) => (
            <Grid key={item.id} item xs={6} md={3} lg={3}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  maxWidth: 345,
                  height: 300,
                  borderRadius: 2,
                  transform: "scale(0.9)",
                  transition: " all ease-in 0.2s",
                  backgroundSize: "cover",
                  "&:hover": {
                    // color: "red",
                    transform: "scale(1)",
                    filter: `drop-shadow(8px 8px 10px black)`,
                  },

                  // border: "1px solid black",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    transform: "scale(1)",
                    mixBlendMode: "multiply",
                    filter: "contrast(2)",
                    objectFit: "contain",
                    // width: 170,
                    height: 170,
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
                  }}
                  image={`https://simplesoft.co.in/qrmenuapp/uploads/cat/${item.id}/${item.img}`}
                />
                <Typography variant="h4" sx={{ mt: 2 }}>
                  {item.name}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="primary"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                    onClick={() => handleEditItem(index, item.id)}
                  >
                    <Tooltip title="Edit">
                      <EditTwoTone />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                    onClick={() => handleDialogOpen(item.id, item.name)}
                  >
                    <Tooltip title="Delete">
                      <DeleteTwoTone />
                    </Tooltip>
                  </IconButton>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 60,
          }}
        >
          <img
            style={{}}
            height={300}
            src="https://cdni.iconscout.com/illustration/premium/thumb/error-404-4344461-3613889.png"
            alt=""
          />
          <Typography variant="h3">No Item Found</Typography>
          <Typography sx={{ mb: 5 }} variant="h6">
            Try something else
          </Typography>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          // color="success"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleOpenModal}
        >
          <Add /> Add New Category
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
          >{`Are You Sure Delete "${deleteName}" Category?`}</Typography>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button color="error" onClick={() => handleDeleteItem()}>
            Delete
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: 600,
            height: 400,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {previewImage && (
              <div>
                <Grid
                  sx={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Button onClick={() => setPreviewImage("")}>
                    Clear Image
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </div>
            )}
            <Grid item>
              {!previewImage && (
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              )}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",

              position: "absolute",
              bottom: 10,
              justifyContent: "space-between",
              left: 120,
            }}
          >
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleAddItem}>
              {editMode ? "Update" : "Add"}
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddNewCategory;
