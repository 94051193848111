import React, { useState, useEffect } from 'react';

const Socket = () => {
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [sentMessages, setSentMessages] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [registered, setRegistered] = useState(false);
  const data={
    "id": 22,
    "total_amount": "750.00",
    "total_items": 5,
    "status": "Completed",
    "table_id": 6,
    "created_at": "2024-05-02T06:50:49.000000Z",
    "updated_at": "2024-05-13T03:30:52.000000Z",
    "items": [
        {
            "id": 7,
            "order_id": 22,
            "menu_item_id": "Beef Roast Source",
            "quantity": 2,
            "category_id": "BREAKFAST",
            "table_id": 6,
            "created_at": "2024-05-02T06:50:49.000000Z",
            "updated_at": "2024-05-02T06:50:49.000000Z"
        },
        {
            "id": 8,
            "order_id": 22,
            "menu_item_id": "Beef Roast Source",
            "quantity": 2,
            "category_id": "BREAKFAST",
            "table_id": 6,
            "created_at": "2024-05-02T06:50:49.000000Z",
            "updated_at": "2024-05-02T06:50:49.000000Z"
        },
        {
          "id": 7,
          "order_id": 22,
          "menu_item_id": "Beef Roast Source",
          "quantity": 2,
          "category_id": "BREAKFAST",
          "table_id": 6,
          "created_at": "2024-05-02T06:50:49.000000Z",
          "updated_at": "2024-05-02T06:50:49.000000Z"
      },
      {
          "id": 8,
          "order_id": 22,
          "menu_item_id": "Beef Roast Source",
          "quantity": 2,
          "category_id": "BREAKFAST",
          "table_id": 6,
          "created_at": "2024-05-02T06:50:49.000000Z",
          "updated_at": "2024-05-02T06:50:49.000000Z"
      },
      {
        "id": 7,
        "order_id": 22,
        "menu_item_id": "Beef Roast Source",
        "quantity": 2,
        "category_id": "BREAKFAST",
        "table_id": 6,
        "created_at": "2024-05-02T06:50:49.000000Z",
        "updated_at": "2024-05-02T06:50:49.000000Z"
    },
    {
        "id": 8,
        "order_id": 22,
        "menu_item_id": "Beef Roast Source",
        "quantity": 2,
        "category_id": "BREAKFAST",
        "table_id": 6,
        "created_at": "2024-05-02T06:50:49.000000Z",
        "updated_at": "2024-05-02T06:50:49.000000Z"
    }
    ]
}
  useEffect(() => {
// init  ws
    const newWs = new WebSocket('wss://blackloop.in:8091');


// first method
    newWs.onopen = () => {
      console.log('WebSocket connection established.');
      setWs(newWs);
    };

// second method
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message:', message);
      if (message.from !== from) {
        setReceivedMessages(prevMessages => [...prevMessages, message]);
      }
    };
// third method
    newWs.onclose = () => {
      console.log('WebSocket connection closed.');
    };
// fourth method
    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      newWs.close();
    };
  }, [from]); 

  const registerUser = () => {
    if (ws && from.trim() !== '') {
      ws.send(JSON.stringify({ command: 'register', userId: from }));
      setRegistered(true); // Update registration status
    }
  };

  const sendMessage = () => {
    if (ws ) {
      ws.send(JSON.stringify({ command: 'message', from, to, data }));
      setSentMessages(prevMessages => [...prevMessages, { from, to, data }]);
      setMessage('');
    }
  };

console.log(sentMessages)
// console.log(receivedMessages)
  return (
    <div className="container">
      <div className="chat">
        {!registered && ( // Only render registration if not registered else chat box
          <div className="input-container">
            <label>Register:</label>
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
            <button onClick={registerUser}>Register</button>
          </div>
        )}
        {registered && ( // Render "From" and "To" fields after registration (chat box)
          <>
            <div className="input-container">
              <label>From:</label>
              <input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
            <div className="input-container">
              <label>To:</label>
              <input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
          </>
        )}
        <div className="input-container">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={sendMessage}>Send</button>
        </div>
        <div className="message-container">
          <h2>Sent Messages:</h2>
          <ul>
            {sentMessages.map((msg, index) => (
              <li key={index}>To: {msg.to}, Message: {msg.message}</li>
            ))}
          </ul>
          <h2>Received Messages:</h2>
          <ul>
            {receivedMessages.map((msg, index) => (
              msg.message && (
                <li key={index}>From: {msg.from}, Message: {msg.message}</li>
              )
            ))}

          </ul>
        </div>
      </div>
    </div>
  );
};

export default Socket;
