import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListWrapper
      // sx={{
      //   display: {
      //     xs: "none",
      //     md: "block",
      //   },
      // }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            // classes={{ root: 'MuiListItem-indicators' }}
            // button
            component={NavLink}
            style={{ textDecoration: "none", color: "inherit" }}
            to="/kitchen/dashboard"
          >
            <svg
              fill="#000000"
              height="20px"
              width="20px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              xmlSpace="preserve"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <g>
                      {" "}
                      <polygon points="85.333,192 128,157.824 170.731,192 158.123,141.056 192,106.581 149.333,106.667 128,64 106.667,106.667 64,106.667 97.387,140.629 "></polygon>{" "}
                      <polygon points="353.387,140.629 341.333,192 384,157.824 426.731,192 414.123,141.056 448,106.581 405.333,106.667 384,64 362.667,106.667 320,106.667 "></polygon>{" "}
                      <polygon points="225.387,140.629 213.333,192 256,157.824 298.731,192 286.123,141.056 320,106.581 277.333,106.667 256,64 234.667,106.667 192,106.667 "></polygon>{" "}
                      <path d="M490.667,0H21.333C9.551,0,0,9.551,0,21.333v469.333C0,502.449,9.551,512,21.333,512h469.333 c11.782,0,21.333-9.551,21.333-21.333V21.333C512,9.551,502.449,0,490.667,0z M469.333,469.333H42.667V42.667h426.667V469.333z"></path>{" "}
                      <path d="M320,213.333c-11.782,0-21.333,9.551-21.333,21.333v64h-85.333v-64c0-11.782-9.551-21.333-21.333-21.333 s-21.333,9.551-21.333,21.333v170.667c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-64h85.333v64 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333V234.667C341.333,222.885,331.782,213.333,320,213.333z"></path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Typography
                  variant="h3"
                  sx={{ ml: 1 }}
                  fontSize={17}
                  fontWeight={800}
                >
                  Digi Menu
                </Typography>
              }
            />
          </ListItem>
          {/* <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/components/forms"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Forms"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={ref}
            onClick={handleOpen}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Others
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem> */}
        </List>
      </ListWrapper>
      {/* <ListWrapper
        sx={{
          display: {
            xs: 'block', md: 'block'
          }
        }}> */}
      {/* <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/components/buttons"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Buttons"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/components/forms"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Forms"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={ref}
            onClick={handleOpen}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Others
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </List> */}
      {/* </ListWrapper> */}
      {/* <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/overview">
          Overview
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/components/tabs">
          Tabs
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/components/cards">
          Cards
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/components/modals">
          Modals
        </MenuItem>
      </Menu> */}
    </>
  );
}

export default HeaderMenu;
