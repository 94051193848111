import React, { Suspense, lazy } from "react";
import PosSidebarLayout from "./Poslayouts/SidebarLayout";
// import BaseLayout from "./layouts/BaseLayout";
import { Navigate } from "react-router-dom";
import Posdashboard from "./PosComponents/Posdashboard";
import PosOrders from "./PosComponents/PosOrders";
import PosBillgenerator from "./PosComponents/PosBillgenerator";
const PosRoutes = [
  {
    path: "",
    element: <PosSidebarLayout />,
    children: [
      {
        path: "/pos/dashboard",
        element: <Posdashboard/>,
      },
      {
        path: "/pos/bill",
        element: <PosBillgenerator/>,
      },
      // {
      //   path: "overview",
      //   element: <Navigate to="/" replace />,
      // },
      // {
      //   path: "login",
      //   element: <h1>login</h1>,
      // },
      // {
      //   path: "status",
      //   children: [
      //     {
      //       path: "",
      //       element: <Navigate to="404" replace />,
      //     },
      //     {
      //       path: "404",
      //       element: <h1>404</h1>,
      //     },
      //     {
      //       path: "500",
      //       element: <h1>440499</h1>,
      //     },
      //     {
      //       path: "maintenance",
      //       element: <h1>mannn</h1>,
      //     },
      //     {
      //       path: "coming-soon",
      //       element: <h1>soon</h1>,
      //     },
      //   ],
      // },
      {
        path: "*",
        element: <h1>not found404</h1>,
      },
    ],
  },
  // {
  //   path: "dashboards",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="tasks" replace />,
  //     },
  //     {
  //       path: "crypto",
  //       element: <Crypto />,
  //     },
  //     {
  //       path: "messenger",
  //       element: <Messenger />,
  //     },
  //   ],
  // },
  // {
  //   path: "management",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="transactions" replace />,
  //     },
  //     {
  //       path: "transactions",
  //       element: <Transactions />,
  //     },
  //     {
  //       path: "profile",
  //       children: [
  //         {
  //           path: "",
  //           element: <Navigate to="details" replace />,
  //         },
  //         {
  //           path: "details",
  //           element: <UserProfile />,
  //         },
  //         {
  //           path: "settings",
  //           element: <UserSettings />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: "/components",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="buttons" replace />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "modals",
  //       element: <Modals />,
  //     },
  //     {
  //       path: "accordions",
  //       element: <Accordions />,
  //     },
  //     {
  //       path: "tabs",
  //       element: <Tabs />,
  //     },
  //     {
  //       path: "badges",
  //       element: <Badges />,
  //     },
  //     {
  //       path: "tooltips",
  //       element: <Tooltips />,
  //     },
  //     {
  //       path: "avatars",
  //       element: <Avatars />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "forms",
  //       element: <Forms />,
  //     },
  //   ],
  // },
];

export default PosRoutes;
