import React, { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Button } from "@mui/material";
import PosRoutes from "./PosRoutes";

function PosMain() {
  const navigate = useNavigate();
  const content = useRoutes(PosRoutes);
  const ispos = sessionStorage.getItem("pos");

  if (ispos)
    return (
      <>
      {content}
      </>
    );
  else {
    return (
      <>
        <h1>you are unauthorized</h1>
        <Button onClick={() => navigate("/")}> back to home</Button>
      </>
    );
  }
}

export default PosMain;
