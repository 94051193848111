import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";

function AddMenuItems() {
  const [category, setcategory] = useState([]);
  const [menuItems, setMenuitems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [categoryid, setcategoryid] = useState(1);
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [itemtype, setItemtype] = useState("veg");
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [search, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState(0);
  const [vegonly, setvegonly] = useState(false);
  const [nonveg, setnonvegonly] = useState(false);
  const [searchopen, setsearchopen] = useState(false);
  const [Dialogopen, setDialogOpen] = useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [deleteName, setDeletename] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const Showcategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/CategoriesList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setcategory(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const ShowMenuItems = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/MenuitemList",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setMenuitems(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    Showcategory();
    ShowMenuItems();
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setEditIndex(null);
    setItemtype("veg");
    setName("");
    setDesc("");
    setcategoryid("");
    setPrice("");
    setImage("");
    setPreviewImage("");
  };
  const handlevegonly = (e) => {
    setvegonly(e.target.checked);
  };
  const handlenonvegonly = (e) => {
    setnonvegonly(e.target.checked);
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  //this filter for sort by category name
  const filter1 = menuItems.filter((items) => {
    if (!filterCategory) return true;
    return items.category_id === filterCategory;
  });
  //this filter for type of item i.e veg or non-veg
  const foodtyped = filter1.filter((item) => {
    if (vegonly && nonveg) return true;
    if (vegonly) return item.type === "veg";
    if (nonveg) return item.type === "nonveg";
    return true;
  });
  //this filter for search by keyword in search field
  const filterItems = foodtyped.filter((item) =>
    item.name.toUpperCase().includes(search.toUpperCase())
  );
  const handlecatId = (event) => {
    setcategoryid(event.target.value);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // console.log(typeof(categoryid));
  const handleClearimage = () => {
    setPreviewImage("");
  };
  const handleDeleteMenuitem = (id) => {
    try {
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/deleteMenuitem/${Deleteid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          ShowMenuItems();
          handleDialogClose();
          // console.log(JSON.stringify(response.data));
         
          enqueueSnackbar(JSON.stringify(response.data), {
            variant: "success",
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      enqueueSnackbar(`SomeThing Went Wrong`, { variant: "error" });

      console.error("Error deleting item:", error);
    }
  };
  // console.log(editIndex)
  const handleAddItem = () => {
    if (editMode) {
      try {
        let data = new FormData();
        data.append("images[]", image);
        data.append("name", name);
        data.append("type", itemtype);
        data.append("category_id", categoryid);
        data.append("description", desc);
        data.append("price", price);
        // const data = {
        //   name: name,
        //   price: price,
        //   description: desc,
        //   category_id: categoryid,
        //   images[]:image,
        //   type:itemtype
        // };
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/updateMenuitem/${editIndex}`,
          headers: {
            Authorization: `Bearer ${token} `,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            // demo();
            enqueueSnackbar(JSON.stringify(response.data), {
              variant: "success",
            });

            ShowMenuItems();
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {
        enqueueSnackbar("Error adding item:", {
          variant: "error",
        });
        console.error("Error updating item:", error);
      }
    } else {
      try {
        let data = new FormData();
        data.append("images[]", image);
        data.append("name", name);
        data.append("type", itemtype);
        data.append("category_id", categoryid);
        data.append("description", desc);
        data.append("price", price);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/addMenuitem",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            enqueueSnackbar(JSON.stringify(response.data), {
              variant: "success",
            });

            ShowMenuItems();
            // demo();
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {
        enqueueSnackbar("Error adding item:", {
          variant: "error",
        });
        console.error("Error adding item:", error);
      }
    }
    handleCloseModal();
  };
  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(filterItems[index].name);
    setPrice(filterItems[index].price);
    setDesc(filterItems[index].description);
    setcategoryid(filterItems[index].category_id);
    setImage(filterItems[index].image);
    // https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${item.id}/${item.img}
    setPreviewImage(
      `https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${id}/${filterItems[index].img}`
    );

    // setPreviewImage(filterItems[index].image);
    setItemtype(filterItems[index].type);
    setOpenModal(true);
  };

  const selectFiltercategory = (e) => {
    setFilterCategory(e.target.value);
  };
  // const selectedCat = category.filter((opt) => opt.id === filterCategory);
  // console.log(selectedCat[0]?.name);

  // const filterbyCategory = menuItems.filter(
  //   (item) => item.category_id === filterCategory
  // );

  // console.log(filterbyCategory);

  // console.log(filterItems);
  // console.log(search)
  const handleitemTypeChange = (event) => {
    setItemtype(event.target.value);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [search, filterCategory, vegonly, nonveg]);
  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteid("");
    setDeletename("");
  };
  const [run, setRun] = useState(true);
  const steps = [
    { target: ".target-1", content: "This Button For Add Items in Menu Card" },
    { target: ".target-2", content: "This Dropdown for Filter by Category" },
    {
      target: ".target-3",
      content: "This Checkbox for Filter Type of Items i.e Veg or non Veg",
    },
    {
      target: ".target-4",
      content: "This Search Icon for Search menu item by entering their name",
    },
    { target: ".id-0", content: "for edit Menu items" },
    { target: ".id-1", content: "for delete menu item" },
  ];
  return (
    <div>
      {/* <Joyride
        steps={steps}
        run={run}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> */}

      {searchopen && (
        <Fade in={searchopen}>
          <TextField
            sx={{
              "& fieldset": { borderRadius: 20 },
              position: "sticky",
              top: 89,
              bgcolor: "white",
              borderRadius: 20,
              zIndex: 5,
              transition: "all 2s",
            }}
            fullWidth
            placeholder="Search here..."
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSearch("")}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Fade>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "white",
          position: "sticky",
          top: searchopen ? 145 : 83,
          zIndex: 5,
        }}
      >
        <FormControl sx={{ width: 200, p: 1 }} className="target-2">
          <Select
            value={filterCategory}
            placeholder="Category"
            onChange={selectFiltercategory}
          >
            <MenuItem value={0}>All</MenuItem>
            {category.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
              // console.log(item.id)
            ))}
          </Select>
        </FormControl>

        <ButtonGroup
          variant="outlined"
          size="small"
          aria-label="Basic button group"
        >
          <FormControlLabel
            className="target-3"
            sx={{ ml: 0.5 }}
            control={
              <Checkbox
                sx={{
                  color: green[800],
                  "&.Mui-checked": {
                    color: green[600],
                  },
                }}
                checked={vegonly}
                onChange={handlevegonly}
              />
            }
            label={<Typography fontWeight={600}>Veg Only</Typography>}
          />

          <FormControlLabel
            className="target-3"
            sx={{ mr: 1 }}
            control={
              <Checkbox
                sx={{
                  color: red[800],
                  "&.Mui-checked": {
                    color: red[600],
                  },
                }}
                checked={nonveg}
                onChange={handlenonvegonly}
              />
            }
            label={<Typography fontWeight={600}>NonVeg Only</Typography>}
          />
          <IconButton
            className="target-4"
            color="primary"
            variant="contained"
            onClick={() => {
              setsearchopen(!searchopen);
              setSearch("");
            }}
          >
            {searchopen ? <Clear /> : <Search />}
          </IconButton>
        </ButtonGroup>
      </Box>
      {filterItems.length > 0 && search && (
        <Typography variant="h4" sx={{ mt: 3, ml: 2 }}>
          {filterItems.length} &nbsp;{" "}
          {filterItems.length > 1 ? "Dishes" : "Dish"} found by this Keyword "
          {search}" word
        </Typography>
      )}
      {filterItems.length > 0 ? (
        <Grid container spacing={2} sx={{ p: 1, mb: 7 }}>
          {filterItems.map((item, index) => (
            <Grid key={index} item xs={6} md={4} lg={4}>
              <Card
                sx={{
                  maxWidth: 345,
                  height: 330,
                  borderRadius: 2,
                  backgroundSize: "cover",
                  transform: "scale(0.9)",
                  transition: " all ease-in 0.2s",
                  "&:hover": {
                    // color: "red",
                    transform: "scale(1)",
                    filter: `drop-shadow(8px 8px 10px black)`,
                  },
                }}
                key={index}
              >
                <img
                  alt=""
                  style={{
                    height: 140,
                    width: "100%",
                    objectFit: "contain",
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(1.1)",

                      transition: "all 0.5s",
                    },
                  }}
                  src={`https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${item.id}/${item.img}`}
                  title={item.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
                  }}
                />
                <CardContent>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      {item.name}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        padding: "2px",
                      }}
                    >
                      <Tooltip title={item.type === "veg" ? "Veg" : "Non Veg"}>
                        <img
                          height={20}
                          src={
                            item.type === "veg"
                              ? "https://banner2.cleanpng.com/20180601/at/kisspng-vegetarian-cuisine-biryani-indian-cuisine-vegetabl-vegetarian-5b11c235a92d48.569689881527890485693.jpg"
                              : "https://banner2.cleanpng.com/20180401/zyq/kisspng-computer-icons-computer-software-light-non-veg-food-5ac181d4128f27.407771801522631124076.jpg"
                          }
                          alt={item.type}
                        />
                      </Tooltip>
                      {/* <Typography sx={{ ml: 0.5 }}>
                        {item.type === "veg" ? "Veg" : "Non Veg"}
                      </Typography> */}
                    </div>
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Price: {item.price}&#8360;
                  </Typography>
                  <Typography
                    sx={{ mt: 1 }}
                    variant="body2"
                    component="p"
                    fontWeight={400}
                  >
                    Desc: {item.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton
                    onClick={() => handleEditItem(index, item.id)}
                    color="primary"
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                  >
                    <Tooltip title="Edit">
                      <EditTwoTone />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleDialogOpen(item.id, item.name)}
                    color="error"
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                  >
                    <Tooltip title="Delete">
                      <DeleteTwoTone />
                    </Tooltip>
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 60,
          }}
        >
          <img
            style={{}}
            height={300}
            src="https://cdni.iconscout.com/illustration/premium/thumb/error-404-4344461-3613889.png"
            alt=""
          />
          <Typography variant="h3">No Item Found</Typography>
          <Typography sx={{ mb: 5 }} variant="h6">
            Try something else
          </Typography>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          // color="warning"
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleOpenModal}
        >
          <Add /> Add Menu Items
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
          >{`Are You Sure Delete "${deleteName}" From Menu Items?`}</Typography>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button color="error" onClick={() => handleDeleteMenuitem()}>
            Delete
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          sx={{
            position: "absolute",
            top: "50%",
            pr: 2,
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            ml: "0px",
            mt: 1,
            height: smallscreen ? 810 : 600,

            width: smallscreen ? 400 : 1100,
          }}
        >
          <Grid item xs={12} md={6} lg={6} justifyContent="center">
            <Box>
              <Typography align="center" variant="h3">
                Add Menu Item
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography fontWeight={600}>Item Name</Typography>
                  <TextField
                    sx={{ mt: 0.5 }}
                    fullWidth
                    placeholder="Item Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Typography fontWeight={600}>Select Category</Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel> */}
                    <Select
                      value={categoryid}
                      placeholder="Category"
                      onChange={handlecatId}
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 200, overflow: "auto" },
                        },
                      }}
                    >
                      {category.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                        // console.log(item.id)
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Typography fontWeight={600}>Price</Typography>
                  <TextField
                    sx={{ mt: 0.5 }}
                    fullWidth
                    placeholder="Item Price"
                    variant="outlined"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography fontWeight={600}>Item Type</Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={itemtype}
                      onChange={handleitemTypeChange}
                    >
                      <FormControlLabel
                        value="veg"
                        control={<Radio color="success" />}
                        label="Veg"
                      />
                      <FormControlLabel
                        value="nonveg"
                        control={<Radio color="error" />}
                        label="Non-Veg"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography fontWeight={600}>Description</Typography>
                  <TextField
                    sx={{ mt: 0.5 }}
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Item Description"
                    variant="outlined"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWeight={600}>Image Preview</Typography>
                    {previewImage && (
                      <Button onClick={handleClearimage}>Clear Image</Button>
                    )}
                  </div>
                </Grid>
                {previewImage && (
                  <div>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={previewImage}
                        // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                        alt="Preview"
                        style={{
                          width: smallscreen ? "100%" : "95%",
                          height: smallscreen ? "150px" : "300px",
                          objectFit: smallscreen ? "fill" : "fill",
                        }}
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
            </Box>
          </Grid>
          <Divider sx={{ color: "primary", width: "100%" }} />
          <div>
            <Button
              sx={{ height: 40, mr: 2 }}
              variant="contained"
              onClick={handleAddItem}
            >
              {editMode ? "Update" : "Add"}
            </Button>
            <Button
              onClick={handleCloseModal}
              sx={{ height: 40, ml: 2 }}
              variant="contained"
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Modal>
    </div>
  );
}
export default AddMenuItems;
