import React from "react";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  Verified,
  VerifiedRounded,
  VerifiedTwoTone,
} from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  margin: "10px",
  curser: "pointer",
  height: 310,
  padding: 10,
  borderRadius: 5,
  transition: "transform 0.3 ease",
  transform: isSelected ? "scale(1.05)" : "scale(1)",
  border: isSelected ? "2px solid green" : "1px solid black",
  backgroundColor: isSelected ? "#afe1af" : "",
  boxShadow: isSelected ? `10px 11px 28px 0px rgba(0,0,0,0.74)` : "",
}));
function SpecialCard({ product, isSelected, onSelect }) {
  const handleclick = () => {
    onSelect(product);
  };
  return (
    <div>
      <StyledCard
        onClick={handleclick}
        isSelected={isSelected}
        sx={{
          transition: " all ease-in 0.2s",
          backgroundSize: "cover",
          "&:hover": {
            // color: "red",
            transform: "scale(1)",
            filter: `drop-shadow(8px 8px 10px black)`,
          },
        }}
      >
        {isSelected && (
          <div style={{ position: "absolute", top: 5, right: 5, zIndex: 5 }}>
            <Box
              sx={{ backgroundColor: "#afe1af", borderRadius: "100%", mb: 5 }}
            >
              <VerifiedRounded sx={{ color: "green" }} />
            </Box>
          </div>
        )}
        <CardMedia
          component="img"
          alt={product.name}
          height="140"
          sx={{ objectFit: "contain" }}
          image={`https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${product.id}/${product.img}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
          }}
        />
        <Divider />
        <br />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Name {product.name}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            Price: {product.price}&#8360;
          </Typography>
          <div
            style={{
              display: "flex",
              padding: "2px",
            }}
          >
            <img
              height={20}
              src={
                product.type === "veg"
                  ? "https://banner2.cleanpng.com/20180601/at/kisspng-vegetarian-cuisine-biryani-indian-cuisine-vegetabl-vegetarian-5b11c235a92d48.569689881527890485693.jpg"
                  : "https://banner2.cleanpng.com/20180401/zyq/kisspng-computer-icons-computer-software-light-non-veg-food-5ac181d4128f27.407771801522631124076.jpg"
              }
              alt={product.type}
            />
            <Typography sx={{ ml: 0.5 }}>
              {product.type === "veg" ? "Veg" : "Non Veg"}
            </Typography>
          </div>
        </CardContent>
      </StyledCard>
    </div>
  );
}

export default SpecialCard;
