import React from "react";
import { useRoutes } from "react-router";
import KitchenRoutes from "./KitchenRoutes";

function KitchenMain() {
  const iskitchen = sessionStorage.getItem("kitchen");
  const content = useRoutes(KitchenRoutes);
  if (iskitchen)
    return (
      <>
    {content}
      </>
    );
  else {
    return <h1>you are unauthorized</h1>;
  }
}

export default KitchenMain;
