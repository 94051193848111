import { CurrencyRupee, Star } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";

function StateCard2() {
  const data = [
    { title: "Today", data: 12000 },
    { title: "Yesterday", data: 1000 },
    { title: "This Week", data: 124000 },
    { title: "This month", data: 121000 },
  ];
  const H4 = styled("h3")(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "16px",
    textAlign: "start",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Box>
        <Card sx={{ px: 3, py: 2, mb: 6 }}>
          <H4 sx={{ textAlign: "center" }}>Total Earning</H4>
        </Card>
        <Grid
          container
          spacing={1}
          sx={{
            transform: "scale(0.9)",
            transition: " all ease-in 0.2s",
            "&:hover": {
              // color: "red",
              transform: "scale(1)",
              filter: `drop-shadow(8px 8px 10px black)`,
            },
          }}
        >
          {data.map((item, index) => (
            <Grid key={index} item xs={6} md={6} lg={6}>
              <Card
                sx={{
                  height: 150,
                  transform: "scale(0.9)",
                  transition: " all ease-in 0.2s",
                  "&:hover": {
                    // color: "red",
                    transform: "scale(1)",
                    filter: `drop-shadow(8px 8px 10px black)`,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h4" gutterBottom align="center">
                    {item.title}
                  </Typography>
                  <Typography align="center">
                    <Star sx={{ fontSize: 40 }} />
                  </Typography>
                  <Typography align="center" variant="h3" sx={{ mt: 1.5 }}>
                    {item.data}
                    <CurrencyRupee />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default StateCard2;
