import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./AuthContext/AuthContext";
import ThemeProviderWrapper from "./theme/ThemeProvider";
import { SidebarProvider } from "./contexts/SidebarContext";
import "./index.css";
import { SnackbarProvider } from "notistack";
ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider>
      <ThemeProviderWrapper>
        <SidebarProvider>
          <AuthProvider>
            <CssBaseline />
            <App />
          </AuthProvider>
        </SidebarProvider>
      </ThemeProviderWrapper>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
