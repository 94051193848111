import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone';
import DoneAllTwoToneIcon from "@mui/icons-material/DoneAllTwoTone";
import {
  Check,
  Clear,
  Close,
  CurrencyRupee,
  Delete,
  // DoneOutlineTwoTone,
  ForkRight,
  FormatListNumbered,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Notes,
  Notifications,
  NotificationsActive,
  Pending,
  Search,
  TableRestaurantTwoTone,
  ThreeDRotation,
  VerifiedTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import mp3file from "./notification sound/notification-sound.wav";
import { useSnackbar } from "notistack";
const Socket = () => {
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  // const [flag, setFlag] = useState(true);

  const [sentMessages, setSentMessages] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [value, setValue] = React.useState("1");
  const [Completed, setCompleted] = useState([]);
  const [OrderPending, setOrderPending] = useState([]);
  const [OrderComplete, setorderComplete] = useState([]);
  const [FilterOrder, setFilterorderList] = useState([]);
  const [searchinput, setSearchinput] = useState("");
  const [waiter, setWaiter] = useState([]);
  const [bill, setBill] = useState([]);
  const [tempWaiter, settempWaiter] = useState([]);
  const [tempBill, setTempBill] = useState([]);

  // const [hotelid,SetHotelid]=useState('')
  // useEffect(() => {
  //   // Load wishlist from local storage when component mounts
  //   const storemsg = JSON.parse(localStorage.getItem("orders")) || [];
  //   setReceivedMessages(storemsg);
  // }, []);
  const getHotelid = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/kitchen/getHotelId",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const Userid = sessionStorage.getItem("userid");
        console.log(`${response.data}-${Userid}`)
        setFrom(`${response.data}-${Userid}`);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    getHotelid();
  }, []);
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   // Update local storage whenever wishlist changes
  //   localStorage.setItem("orders", JSON.stringify(FilterOrder));
  // }, [FilterOrder]);
  // console.log(receivedMessages)
  // console.log(typeof(from)
  const registerUser = () => {
    if (ws && from.trim() !== "") {
      ws.send(JSON.stringify({ command: "register", userId: from }));
      setRegistered(true); // Update registration status
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const orderPending = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/kitchen/OrderListPending",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setOrderPending(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    // init  ws
    const newWs = new WebSocket("wss://blackloop.in:8091");

    // first method
    newWs.onopen = () => {
      // console.log("WebSocket connection established.");
      setWs(newWs);
      newWs.send(JSON.stringify({ command: "register", userId: from }));
    };

    // second method
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // console.log("Received message:", message);
      if (message.from !== from) {
        orderPending();
       
        enqueueSnackbar(`New Notification`, { variant: "success" });

        const audio = new Audio(mp3file);
        audio.play();
        setReceivedMessages((prevMessages) => [...prevMessages, message]);
      }
    };
    // third method
    newWs.onclose = () => {
      // console.log("WebSocket connection closed.");
    };
    // fourth method
    newWs.onerror = (error) => {
      // console.error("WebSocket error:", error);
    };

    return () => {
      newWs.close();
    };
  }, [from]);

  useEffect(() => {
    let temparray = [];
    receivedMessages.forEach((item) => {
      if (item.command == "order") {
        temparray.push(item);
      }
    });
    setFilterorderList(temparray);
  }, [receivedMessages]);
  useEffect(() => {
    let temparray = [];
    receivedMessages.forEach((item) => {
      if (item.command == "waiter") {
        temparray.push(item);
      }
    });
    setWaiter(temparray);
  }, [receivedMessages]);

  useEffect(() => {
    let temparray = [];
    receivedMessages.forEach((item) => {
      if (item.command == "bill") {
        temparray.push(item);
      }
    });

    setBill(temparray);
  }, [receivedMessages]);
  useEffect(() => {
    const interval = 10000;
    const deletefirst = () => {
      setReceivedMessages((prevdata) => {
        if (prevdata.length > 0) {
          const newdata = prevdata.slice(1);
          return newdata;
        }
        return prevdata;
      });
    };
    const timeoutid = setTimeout(deletefirst, interval);
    return () => clearTimeout(timeoutid);
  }, [receivedMessages]);

  // console.log(bill);
  // console.log(waiter);
  const sendMessage = () => {
    if (ws && message.trim() !== "" && from.trim() !== "" && to.trim() !== "") {
      ws.send(JSON.stringify({ command: "message", from, to, message }));
      setSentMessages((prevMessages) => [
        ...prevMessages,
        { from, to, message },
      ]);
      setMessage("");
    }
  };
  const SeeMoretext = ({ text, previewlength = 120 }) => {
    const [expand, setexpand] = useState(false);
    const handletoggle = () => {
      setexpand(!expand);
    };
    const previewtext = text.substring(0, previewlength);
    const remaingtext = text.substring(previewlength);

    return (
      <Box>
        <Typography
          variant="h4"
          align="left"
          sx={{
            // mb: 1.5,
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "15rem",
            wordWrap: "break-word",
            height: expand ? "" : 30,
            // WebkitLineClamp: 4,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          <Notes />
          &nbsp; Note:&nbsp;{previewtext}
          <Collapse in={expand} timeout="auto" unmountOnExit>
            {remaingtext}
          </Collapse>
        </Typography>
        <Button
          onClick={handletoggle}
          sx={{ color: "black", opacity: 0.7 }}
          size="small"
        >
          {expand ? "See Less " : "See More"}
        </Button>
      </Box>
    );
  };
  // console.log(receivedMessages);
  // console.log(receivedMessages.ma.data.table_id)
  //   {
  //     "total_amount": 750,
  //     "total_items": 5,
  //     "table_id": 6,
  //     "items": [
  //         {
  //             "category_id": 1,
  //             "menu_item_id": 1,
  //             "quantity": 2
  //         },
  //         {
  //             "category_id": 1,
  //             "menu_item_id": 2,
  //             "quantity": 2
  //         }
  //     ]
  // }
  // const handleDone = (item, index1) => {
  //   setFilterorderList((prev) => {
  //     return FilterOrder.filter((item, index) => index !== index1);
  //   });
  //   setCompleted((prev) => [...prev, item]);
  // };
  // console.log(Completed)
  const getOrderList = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/kitchen/OrderList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getorderCompleted = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/kitchen/OrderListCompleted",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setorderComplete(response.data);
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar(`SomeThing Went Wrong`, { variant: "error" });

      });
  };
  const handleUpdate = (id) => {
    // console.log(id);
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrmenuapp/api/v1/kitchen/updateOrderStatus/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        getorderCompleted();
        orderPending();
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar(`SomeThing Went Wrong`, { variant: "error" });

      });
  };
  useEffect(() => {
    getOrderList();
    orderPending();
    getorderCompleted();
  }, []);
  useEffect(() => {
    const timeid = setTimeout(() => {
      // console.log(inputvalue);
    }, 800);
    return () => {
      clearTimeout(timeid);
    };
  }, [searchinput]);
  const inputHandler = (e) => {
    // const newnumber = parseInt(e.target.value)
    setSearchinput(e.target.value);
  };
  const filterItems = OrderComplete.filter((item) =>
    item.table_id.toString().toLowerCase().includes(searchinput.toString().toLowerCase())
  );
  // console.log(filterItems);
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" }, p: 3 }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="h5">Table Id: {row.table_id}</Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            <Typography variant="h5">{row.total_items}</Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            <Typography variant="h5">{row.total_amount}&nbsp;₹</Typography>
          </TableCell>{" "}
          <TableCell align="right">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography variant="h5">{row.status}</Typography>
              <VerifiedTwoTone sx={{ color: "green" }} />
            </div>
          </TableCell>
          {/* <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Orders
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Menu Items</TableCell>
                      {/* <TableCell align="right">Amount</TableCell> */}
                      <TableCell align="right">Quantity</TableCell>
                      {/* <TableCell align="right">Total price ($)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.created_at}
                        </TableCell>
                        <TableCell>{historyRow.menu_item_id}</TableCell>
                        {/* <TableCell align="right">{historyRow.price}</TableCell> */}
                        <TableCell align="right">
                          {historyRow.quantity}
                        </TableCell>
                        {/* <TableCell align="right">
                          {historyRow.quantity * 100}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  function Row2(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" }, p: 3 }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="h5">Table Id: {row.table_id}</Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            <Typography variant="h5">{row.total_items}</Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            <Typography variant="h5">{row.total_amount}&nbsp;₹</Typography>
          </TableCell>{" "}
          <TableCell align="right">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography variant="h5">Completed</Typography>
              <VerifiedTwoTone sx={{ color: "green" }} />
            </div>
          </TableCell>
          {/* <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Orders
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Menu Items</TableCell>
                      {/* <TableCell align="right">Amount</TableCell> */}
                      <TableCell align="right">Quantity</TableCell>
                      {/* <TableCell align="right">Total price ($)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.type}
                        </TableCell>
                        <TableCell>{historyRow.name}</TableCell>
                        {/* <TableCell align="right">{historyRow.price}</TableCell> */}
                        <TableCell align="right">
                          {historyRow.quantity}
                        </TableCell>
                        {/* <TableCell align="right">
                          {historyRow.quantity * 100}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const Backgroundcolor = (name) => {
    // switch (name) {
    //   case "POS":
    //     return "red";
    //   case !isNaN(name):
    //     return "blue";
    //   default:
    //     return "#18bd63";
    // }
    if (!isNaN(name)) {
      return "blue";
    } else if (name == "POS") {
      return "red";
    } else {
      return "#18bd63";
    }
  };
  const handleDeleteNotification = (id) => {
    setReceivedMessages((prevNotifications) =>
      prevNotifications.filter(
        (notification) =>
          notification.from !== id && notification.command == "waiter"
      )
    );
  };
  const handleDeleteNotification1 = (id) => {
    setReceivedMessages((prevNotifications) =>
      prevNotifications.filter(
        (notification) =>
          notification.from !== id && notification.command == "bill"
      )
    );
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(smallscreen);
  // const audio=new Audio();
  // console.log(Completed)
  return (
    <div>
      
      {/* <div
        style={{ display: "flex", justifyContent: "flex-end", marginRight: 10,position:'fixed',top:130,right:10,zIndex:100 }}
      >
        <div>
          <IconButton color="inherit" onClick={handleOpenMenu}>
            <Badge badgeContent={bill.length + waiter.length} color="error">
              <NotificationsActive />
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            TransitionComponent={Grow}
           
          >
            {waiter.length > 0 ? (
              <Typography variant="h6" fontWeight={600}>
                Waiter
              </Typography>
            ) : (
              <Grow in={true} timeout={500}>
                <MenuItem>
                  <ListItemIcon>
                    <Close fontSize="small" style={{ color: "red" }} />
                  </ListItemIcon>
                  <ListItemText primary="No Call For waiter right now  !!" />
                </MenuItem>
              </Grow>
            )}
            {/* Notifications List */}
      {/* {waiter.map((notification) => (
              <Grow key={notification.id} in={true} timeout={500}>
                <MenuItem>
                  <ListItemIcon>
                    <Notifications fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>Waiter Request by Table no:{notification.from}</Typography>
                    }
                  />
                  <Clear
                    onClick={() => handleDeleteNotification(notification.from)}
                  />
                  {/* <Clear/>
                </MenuItem>
              </Grow>
            ))} */}
      {/* Delete All Notifications */}
      {/* {waiter.length > 0 ? (
              <Grow in={true} timeout={500}>
                <MenuItem onClick={() => setWaiter([])}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Clear all notifications" />
                </MenuItem>
              </Grow>
            ) : (
              <Grow in={true} timeout={500}>
                <MenuItem>
                  <ListItemIcon>
                    <Close fontSize="small" style={{ color: "red" }} />
                  </ListItemIcon>
                  <ListItemText primary="No notifications !!" />
                </MenuItem>
              </Grow>
            )} */}
      {/* <Divider />
            {bill.length > 0 ? (
              <Typography variant="h6" fontWeight={600}>
                Bill
              </Typography>
            ) : (
              <Grow in={true} timeout={500}>
                <MenuItem>
                  <ListItemIcon>
                    <Close fontSize="small" style={{ color: "red" }} />
                  </ListItemIcon>
                  <ListItemText primary="No Bill Request right now  !!" />
                </MenuItem>
              </Grow>
            )}
            {bill.map((bills) => (
              <Grow key={bills.id} in={true} timeout={500}>
                <MenuItem>
                  <ListItemIcon>
                    <Notifications fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>Bill Request by Table no:{bills.from}</Typography>
                    }
                  />
                  <Clear
                    onClick={() => handleDeleteNotification1(bills.from)}
                  /> */}
      {/* <Clear/> */}
      {/* </MenuItem>
              </Grow>
            ))} */}

      {/* Delete All Notifications */}
      {/* <Divider />
            {bill.length + waiter.length > 0 && (
              <Grow in={true} timeout={500}>
                <MenuItem onClick={() => setReceivedMessages([])}>
                  <ListItemIcon>
                    <Delete sx={{ color: "red" }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Clear all notifications" />
                </MenuItem>
              </Grow>
            )}
          </Menu>
        </div>
      </div>  */}
      <Grid
        container
        spacing={2}
        sx={{
          p: 1,
          position: "fixed",
          top: 100,
          zIndex: 100,
          backgroundColor: "white",
        }}
      >
        <Grid item lg={4.7} md={6} xs={6}>
          <Box sx={{ border: "1px solid black", height: 60 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ ml: 1 }} align="center">
                Waiter
              </Typography>
              {/* <Button> Clear Notification</Button> */}
            </div>
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {waiter.map((notification) => (
                <Grow key={notification.id} in={true} timeout={500}>
                  <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                    <Typography>{notification.from}</Typography>
                    {/* <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleDeleteNotification(notification.from)
                      }
                    /> */}
                  </div>
                </Grow>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4.7} md={6} xs={6}>
          <Box sx={{ border: "1px solid black", height: 60 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ ml: 1 }} align="center">
                Bill
              </Typography>
              {/* <Button> Clear Notification</Button> */}
            </div>
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {bill.map((notification) => (
                <Grow key={notification.id} in={true} timeout={500}>
                  <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                    <Typography>{notification.from}</Typography>
                    {/* <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleDeleteNotification1(notification.from)
                      }
                    /> */}
                  </div>
                </Grow>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ typography: "body1", height: 90, mt: 14 }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 0,
              backgroundColor: "white",
              pb: 1,
              position: "fixed",
              top: 170,
              zIndex: 100,
              height: 70,
            }}
          >
            <TabList
              // sx={{ mt: 1, mb: 1 }}
              sx={{
                position: "fixed",
                top: 165,
                zIndex: 100,
                width: "100%",
                backgroundColor: "white",
                mt: 1,
                mb: 1,
                height: 40,

                left: !smallscreen && 150,
              }}
              centered
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  minWidth: 200, // a number of your choice
                  width: 200,
                }}
                value="1"
                icon={
                  <Badge
                    sx={{ mt: 1 }}
                    // badgeContent={
                    //   FilterOrder.length + OrderPending.length
                    // }
                    badgeContent={OrderPending.length}
                    color="error"
                  >
                    Pending&nbsp;
                    <Pending />
                  </Badge>
                }
              />
              <Tab
                sx={{
                  minWidth: 200, // a number of your choice
                  width: 200,
                }}
                // label="Completed"
                value="2"
                icon={
                  <Badge
                    sx={{ mt: 1 }}
                    // badgeContent={OrderComplete.length+Completed.length}
                    badgeContent={OrderComplete.length}
                    color="error"
                  >
                    Completed &nbsp; <Close />
                  </Badge>
                }
              />
            </TabList>
          </Box>
          <TabPanel value="1">
          <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid lg={4} md={4} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  <Card
                    sx={{ backgroundColor: "blue", height: 30, width: 30 }}
                  />
                  <Typography variant="h5"> Orders From Tables</Typography>
                </div>
              </Grid>
              <Grid lg={4} md={4} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  <Card
                    sx={{ backgroundColor: "red", height: 30, width: 30 }}
                  />
                  <Typography variant="h5"> Orders From POS</Typography>
                </div>
              </Grid>
              <Grid lg={4} md={4} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  <Card
                    sx={{ backgroundColor: "#18bd63", height: 30, width: 30 }}
                  />
                  <Typography variant="h5"> Orders From Shop/Office</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              {OrderPending.map((item, index) => (
                <Grid lg={4} md={4} xs={12} item key={index}>
                  <Card sx={{ height: 550 }}>
                  <Box
                      sx={{
                        backgroundColor: Backgroundcolor(item.table_id),
                        p: 2,
                        color: "black",
                      }}
                    >
                      <Typography color="black" variant="h4" align="left">
                        <TableRestaurantTwoTone /> Table No:&nbsp;{" "}
                        {item?.table_id}
                      </Typography>
                      <Typography color="black" variant="h4" align="left">
                        <FormatListNumbered /> Total Items:&nbsp;{" "}
                        {item.total_items}
                      </Typography>
                      <Typography color="black" variant="h4" align="left">
                        <CurrencyRupee /> Total Amount:&nbsp;{" "}
                        {item.total_amount}&nbsp;&#8360;
                      </Typography>
                      {item.note && (
                        <>
                          <SeeMoretext text={item.note} />
                        </>
                      )}
                    </Box>
                    <CardContent>
                      <List
                        sx={{
                          mt: 1,
                          maxHeight: 230,
                          height: 230,
                          overflow: "auto",
                          scrollbarWidth: "thin",
                          // "*::-webkit-scrollbar": {
                          //   width: "0px",
                          // },
                          // "*::-webkit-scrollbar-track": {
                          //   background: "#E4EFEF",
                          // },
                          // "*::-webkit-scrollbar-thumb": {
                          //   background: "#1D388F61",
                          //   borderRadius: "0px",
                          // },
                        }}
                      >
                        {item?.items.map((data, index) => (
                          // <ListItem key={index} sx={{backgroundColor:item.type=== "veg"?'#16be61':'#eeaa00',my:1}}>

                          <ListItem key={index}>
                            <ListItemAvatar>
                              <div
                                style={{
                                  display: "flex",
                                  padding: "2px",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor:
                                      data.type === "veg"
                                        ? "#16be61"
                                        : "#ea6262",
                                    height: 30,
                                    width: 20,
                                    mb: 2,
                                  }}
                                ></Box>
                                {/* <Typography sx={{ ml: 0.5 }}>
                        {item.type === "veg" ? "Veg" : "Non Veg"}
                      </Typography> */}
                              </div>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <>
                                  <Typography variant="h4" fontWeight={600}>
                                    Category:&nbsp; {data.cat_name}
                                  </Typography>
                                  <Typography variant="h4" fontWeight={600}>
                                    Item Name:&nbsp;{data.item_name}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <Typography variant="h6" fontWeight={400}>
                                  Quantity:&nbsp;{data.quantity}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    <div
                      style={{
                        display: "flex",
                        position: "static",

                        bottom: 0,
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#18bd63",
                          "&:hover": {
                            color: "black",
                          },
                        }}
                        onClick={() => handleUpdate(item.id)}
                      >
                        <DoneAllTwoToneIcon />
                        Mark as Completed
                      </Button>
                    </div>
                  </Card>
                </Grid>
              ))}
              {/* {FilterOrder.map((item, index) => (
                  <>
                    <Grid lg={6} item key={index}>
                      <Card sx={{ height: 550 }}>
                        <Box sx={{ backgroundColor: "#18bd63", p: 2 }}>
                          <Typography color="black" variant="h3" align="left">
                            <TableRestaurantTwoTone /> Table No:&nbsp;{" "}
                            {item?.data?.table_id}
                          </Typography>
                          <Typography color="black" variant="h3" align="left">
                            <FormatListNumbered /> Total Items:&nbsp;{" "}
                            {item?.data?.total_items}
                          </Typography>
                          <Typography color="black" variant="h3" align="left">
                            <CurrencyRupee /> Total Amount:&nbsp;{" "}
                            {item?.data?.total_amount}&nbsp;&#8360;
                          </Typography>
                        </Box>
                        <CardContent>
                          <List
                            sx={{
                              mt: 3,
                              maxHeight: 260,
                              height: 260,
                              overflow: "auto",
                              scrollbarWidth: "thin",
                            }}
                          >
                            {item?.data?.items.map((data, index) => (
                              <ListItem key={index}>
                                <ListItemAvatar>
                                  <Box
                                    sx={{
                                      backgroundColor:
                                        data.type === "veg"
                                          ? "#16be61"
                                          : "#ea6262",
                                      height: 30,
                                      width: 20,
                                      mb: 2,
                                    }}
                                  ></Box>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography variant="h4" fontWeight={600}>
                                        Item Name:&nbsp;{data.name}
                                      </Typography>
                                    </>
                                  }
                                  secondary={
                                    <Typography variant="h6" fontWeight={400}>
                                      Quantity:&nbsp;{data.quantity}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </CardContent>
                        <div
                          style={{
                            display: "flex",
                            position: "relative",

                            bottom: 0,
                            justifyContent: "space-around",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{ bgcolor: "#18bd63" }}
                            onClick={() => handleDone(item, index)}
                          >
                            <DoneAllTwoToneIcon />
                            Mark as Completed
                          </Button>
                        </div>
                      </Card>
                    </Grid>
                  </>
                ))} */}
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            {/* <Grid container spacing={2} justifyContent="center">
                {OrderComplete.map((item, index) => (
                  <Grid lg={6} item>
                    <Card sx={{ height: 400 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      ></div>
                      <CardContent>
                        <Typography variant="h3" align="center">
                          Table Id {item?.table_id}
                        </Typography>
                        <Typography variant="h3" align="center">
                          Total Items {item.total_items}
                        </Typography>
                        <Typography variant="h3" align="center">
                          Total Amount {item.total_amount}
                        </Typography>
                        <List
                          sx={{
                            mt: 3,
                            maxHeight: 260,
                            overflow: "auto",
                            scrollbarWidth: "thin",
                          }}
                        >
                          {item?.items.map((data, index) => (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <Avatar />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <>
                                    <Typography variant="h4" fontWeight={600}>
                                      Category Id:&nbsp; {data.category_id}
                                    </Typography>
                                    <Typography variant="h4" fontWeight={600}>
                                      Menu Item Id:&nbsp;{data.menu_item_id}
                                    </Typography>
                                  </>
                                }
                                secondary={
                                  <Typography variant="h6" fontWeight={400}>
                                    Quantity:&nbsp;{data.quantity}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid> */}

            {/* {Completed.map((item, index) => (
                  <>
                    <Grid lg={6} item>
                      <Card sx={{ height: 400 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                          }}
                        ></div>
                        <CardContent>
                          <Typography variant="h3" align="center">
                            Order from{item.from}
                          </Typography>
                          <Typography variant="h3" align="center">
                            Table Id {item?.data?.table_id}
                          </Typography>
                          <Typography variant="h3" align="center">
                            Total Items {item?.data?.total_items}
                          </Typography>
                          <Typography variant="h3" align="center">
                            Total Amount {item?.data?.total_amount}
                          </Typography>
                          <List
                            sx={{
                              mt: 3,
                              maxHeight: 260,
                              overflow: "auto",
                              scrollbarWidth: "thin",
                            }}
                          >
                            {item?.data?.items.map((data, index) => (
                              <ListItem key={index}>
                                <ListItemAvatar>
                                  <Avatar />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography variant="h4" fontWeight={600}>
                                        Category Id:&nbsp; {data.category_id}
                                      </Typography>
                                      <Typography variant="h4" fontWeight={600}>
                                        Menu Item Id:&nbsp;{data.menu_item_id}
                                      </Typography>
                                    </>
                                  }
                                  secondary={
                                    <Typography variant="h6" fontWeight={400}>
                                      Quantity:&nbsp;{data.quantity}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ))} */}
            <TextField
              variant="outlined"
              // type="number"
              sx={{
                // backgroundColor: "alternate.dark",
                position: "sticky",
                borderRadius: 30,
                mb: 3,
                zIndex: 5,
                top: 67,
                [`& fieldset`]: {
                  borderRadius: 30,
                },
              }}
              fullWidth
              placeholder="Enter Table No"
              value={searchinput}
              onChange={inputHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchinput && (
                      <Clear
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSearchinput("")}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 470,
                overflow: "auto",
                scrollbarWidth: "thin",
                // border: "1px solid black",
                borderRadius: 3,
              }}
            >
              <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Table No</TableCell>
                    <TableCell align="right">Totel Items</TableCell>
                    <TableCell align="right">Total Bill</TableCell>
                    <TableCell align="right">Status</TableCell>
                    {/* <TableCell align="right"></TableCell> */}
                  </TableRow>
                </TableHead>
                {searchinput ? (
                  <TableBody>
                    {filterItems.map((row) => (
                      <Row key={row.name} row={row} />
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    {OrderComplete.map((row) => (
                      <Row key={row.name} row={row} />
                    ))}
                    {/* {Completed.map((row) => (
                        <Row2 key={row.name} row={row.data} />
                      ))} */}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </TabPanel>
          {/* <TabPanel value="3">Item Three</TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
};

export default Socket;
