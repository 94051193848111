import {
  Add,
  Clear,
  Close,
  GridView,
  KeyboardArrowLeft,
  KeyboardBackspace,
  Remove,
  Search,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { green, red } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
function Posdashboard() {
  const [data1, Setdata1] = useState([]);
  const [categories, setCategories] = useState("all");
  const tid = sessionStorage.getItem("TID");

  //   const [styleType, setStyleType] = useState("grid"); // Default to grid style
  const [cartItems, setCartItems] = useState([]);
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState("");
  const [to, setTo] = useState("");
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [table, settable] = useState([]);
  const [from, setFrom] = useState("POS");
  const { enqueueSnackbar } = useSnackbar();
  const demo = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/pos/HotelTableList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const sortdata = response.data.sort((a, b) => a.name - b.name);
        // console.log(sortdata);
        settable(sortdata);
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar(`something went wrong`, { variant: "error" });
      });
  };
  useEffect(() => {
    demo();
  }, []);
  useEffect(() => {
    // init  ws
    const newWs = new WebSocket("wss://blackloop.in:8091");

    // first method
    newWs.onopen = () => {
      console.log("WebSocket connection established.");
      setWs(newWs);
      newWs.send(JSON.stringify({ command: "register", userId: from }));
    };

    // second method
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // console.log("Received message:", message);
      if (message.from !== from) {
        setReceivedMessages((prevMessages) => [...prevMessages, message]);
      }
    };
    // third method
    newWs.onclose = () => {
      // console.log("WebSocket connection closed.");
    };
    // fourth method
    newWs.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      newWs.close();
    };
  }, [from]);
  const getHotelid = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/getHotelId",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // const Userid = sessionStorage.getItem("userid");
        // console.log(`${response.data}-19`);
        setTo(`${response.data}-19`);
      })
      .catch((error) => {
        // enqueueSnackbar(`something went wrong`,{variant:'error'} );
        // console.log(error);
      });
  };
  useEffect(() => {
    getHotelid();
  }, []);
  const GetMenuitems = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/MenuitemList",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        Setdata1(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(`something went wrong`, { variant: "error" });
        // console.log(error);
      });
  };
  useEffect(() => {
    // Load wishlist from local storage when component mounts
    const storedcart = JSON.parse(localStorage.getItem("PosCart")) || [];
    setCartItems(storedcart);
  }, []);
  const [Categories1, setCategories1] = useState([]);
  const getcategory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/CategoriesList",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCategories1(response.data);
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar(`something went wrong`, { variant: "error" });
      });
  };
  useEffect(() => {
    getcategory();
    GetMenuitems();
  }, []);
  const filter1 = data1.filter((items) => {
    if (categories == "all") return true;
    return items.category_id === categories;
  });
  const addToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    // console.log(existingItem)
    if (existingItem) {
      const updatedCart = cartItems.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      );
      setCartItems(updatedCart);
      enqueueSnackbar(`Quantity of ${item.name} is increase by 1`, {
        variant: "info",
      });
    } else {
      const { id, type, category_id, name, category, price, images } = item;
      // console.log(type,id,title,category,price)
      // const newItem = {
      //   id,
      //   type,
      //   category_id,
      //   name,
      //   category,
      //   price,
      //   images,
      //   quantity: 1,
      // };
      const newItem = { ...item, quantity: 1 };
      setCartItems([...cartItems, newItem]);
      enqueueSnackbar(`${item.name} is added to cart`, { variant: "success" });
    }
  };
  const theme = useTheme();
  const [inputvalue, setInputvalue] = useState("");
  useEffect(() => {
    const timeid = setTimeout(() => {
      // console.log(inputvalue);
    }, 800);
    return () => {
      clearTimeout(timeid);
    };
  }, [inputvalue]);
  const inputHandler = (e) => {
    setInputvalue(e.target.value);
  };
  const [vegonly, setvegonly] = useState(false);
  const [nonveg, setnonvegonly] = useState(false);
  const [checkoutdata, setCheckoutdata] = useState([]);
  const [price1, setPrice1] = useState(0);
  const handletableChange = (event) => {
    setFrom(event.target.value);
  };
  const handlevegonly = (e) => {
    setvegonly(e.target.checked);
  };
  const handlenonvegonly = (e) => {
    setnonvegonly(e.target.checked);
  };

  //this function for checking food type
  const foodtyped = filter1.filter((item) => {
    if (vegonly && nonveg) return true;
    if (vegonly) return item.type === "veg";
    if (nonveg) return item.type === "nonveg";
    return true;
  });
  const totalquantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  // console.log(foodtyped);
  //this function for filter by word of search box
  const filterItems = foodtyped.filter((item) =>
    item.name.toUpperCase().includes(inputvalue.toUpperCase())
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [vegonly, nonveg, inputvalue]);
  useEffect(() => {
    const data = cartItems.map(({ id, name, quantity, category_id, type }) => ({
      id,
      name,
      type,
      quantity,
      category_id,
    }));
    setCheckoutdata(data);
  }, [cartItems]);
  // console.log(checkoutdata)
  const handleRemove = (items) => {
    const arr = cartItems.filter((item) => item.id !== items.id);
    setCartItems(arr);
    enqueueSnackbar(`${items.name} Removed from  cart!`, { variant: "error" });
  };

  const handleMinus = (card_id) => {
    setCartItems((cart) =>
      cart.map((item) =>
        card_id === item.id
          ? { ...item, quantity: item.quantity - (item.quantity > 1 ? 1 : 0) }
          : item
      )
    );
  };
  const handlePlus = (card_id) => {
    setCartItems((cart) =>
      cart.map((item) =>
        card_id === item.id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };
  // console.log(cartItems)
  const handlePrice = () => {
    let sum = 0;
    cartItems.map((item) => {
      let total = parseInt(item.price * item.quantity);
      sum += total;
      setPrice1(sum);
      return sum;
    });
  };
  useEffect(() => {
    handlePrice();
  }, [cartItems]);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [text, Settext] = useState("");
  const handleText = (e) => {
    Settext(e.target.value);
  };
  useEffect(() => {
    // Update local storage whenever wishlist changes
    localStorage.setItem("PosCart", JSON.stringify(cartItems));
  }, [cartItems]);
  const data = {
    note: text,
    table_id: from,
    total_items: totalquantity,
    total_amount: price1,
    items: checkoutdata,
  };
  const navigate = useNavigate();

  //   console.log(data);
  const sendMessage = () => {
    if (ws) {
      ws.send(JSON.stringify({ command: "order", from, to, data }));
      setMessage("");
      Settext("");
      // console.log(data)
      navigate("/pos/bill", { state: cartItems });
    }
  };
  const handlePlaceorder = () => {
    // setpreviousorder((prev) => [...prev, ...previousdata]);
    // setpreviousorder((prev) => [...prev, ...previousdata]);
    // addnewdata(previousdata)
    // console.log(previousdata)
    // sethistory(cartItems);
    setCartItems([]);
    enqueueSnackbar(`Order Placed`, { variant: "success" });

    sendMessage();
    // showSwal();
    const newWs = new WebSocket("wss://blackloop.in:8091");

    // first method
    newWs.onopen = () => {
      console.log("WebSocket connection established.");
      setWs(newWs);
      newWs.send(JSON.stringify({ command: "register", userId: from }));
    };
  };
  //   const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(table)
  return (
    <div>
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          display: "flex",
          // backgroundColor: "alternate.dark",
          p: 1,
          scrollbarWidth: "thin",
          //   "&::-webkit-scrollbar": { display: "thin" },
        }}
      >
        <Box
          sx={{
            mr: 1,
            ml: 1,
            filter:
              theme.palette.mode === "light"
                ? `brightness(100%)`
                : `brightness(80%)`,
          }}
          onClick={() => setCategories("all")}
        >
          <Card
            sx={{
              backgroundColor: categories == "all" ? "#B9D9EB" : "#DFF5FF",
              color: "black",
              borderRadius: 1,
              cursor: "pointer",
              transition: " all ease-in 0.2s",

              transform: categories == "all" ? "scale(1)" : "scale(0.9)",
              height: 130,
              borderBottom: categories == "all" && "4px solid #00416A",
              p: 1,
              "&:hover": {
                backgroundColor: "#B9D9EB",
              },
            }}
          >
            <img
              height={50}
              width={110}
              style={{
                objectFit: "contain",
                mixBlendMode: "multiply",
                filter: "contrast(1.2)",
              }}
              src="https://e7.pngegg.com/pngimages/999/717/png-clipart-flay-lay-graphy-of-food-on-bowls-indian-cuisine-vegetarian-cuisine-street-food-pakistani-cuisine-punjabi-cuisine-indian-food-food-recipe-thumbnail.png"
              alt=""
            />
            <CardContent>
              <Typography
                align="center"
                variant="body1"
                sx={{ cursor: "pointer" }}
              >
                All
              </Typography>
            </CardContent>
          </Card>
          {/* </motion.div> */}
        </Box>
        {Categories1.map((category, index) => (
          <Box
            sx={{
              mr: 1,
              ml: 1,
              filter:
                theme.palette.mode === "light"
                  ? `brightness(100%)`
                  : `brightness(80%)`,
            }}
            key={index}
            onClick={() => setCategories(category.id)}
          >
            {/* <motion.div
                          initial={{ x: 300 }}
                          animate={{ x: 0 }}
                          transition={{ ease: "linear", delay: index / 8 }}
                        > */}
            <Card
              sx={{
                backgroundColor:
                  categories == category.id ? "#B9D9EB" : "#DFF5FF",
                borderRadius: 1,
                cursor: "pointer",
                height: 130,
                transition: " all ease-in 0.2s",
                color: "black",

                transform:
                  categories == category.id ? "scale(1)" : "scale(0.9)",
                borderBottom: categories == category.id && "4px solid #00416A",
                p: 1,
                "&:hover": {
                  backgroundColor: "#B9D9EB",
                },
              }}
            >
              <img
                height={50}
                width={110}
                style={{
                  objectFit: "contain",
                  // borderRadius: "50%",
                  mixBlendMode: "multiply",
                  filter: "contrast(1.2) ",
                }}
                // sx={{ width: 70, height: 70 }}
                src={`https://simplesoft.co.in/qrmenuapp/uploads/cat/${category.id}/${category.img}`}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
                }}
              />
              <CardContent>
                <Typography
                  align="center"
                  variant="body1"
                  // color="text.primary"
                  sx={{ cursor: "pointer", fontWeight: 500 }}
                >
                  {category.name}
                </Typography>
              </CardContent>
            </Card>
            {/* </motion.div> */}
          </Box>
        ))}
      </Box>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 1,
            mt: 1,
            backgroundColor: "background.paper",
            position: "sticky",
            zIndex: 5,
            top: 135,
            p: 1,
          }}
        >
          <ButtonGroup>
            <FormControlLabel
              className="target-3"
              sx={{ ml: 0.5 }}
              control={
                <Checkbox
                  sx={{
                    color: green[800],
                    "&.Mui-checked": {
                      color: green[600],
                    },
                  }}
                  checked={vegonly}
                  onChange={handlevegonly}
                />
              }
              label={
                <Typography fontWeight={500} fontSize={12}>
                  Veg Only
                </Typography>
              }
            />

            <FormControlLabel
              sx={{ mr: 1 }}
              className="target-4"
              control={
                <Checkbox
                  sx={{
                    color: red[800],
                    "&.Mui-checked": {
                      color: red[600],
                    },
                  }}
                  checked={nonveg}
                  onChange={handlenonvegonly}
                />
              }
              label={
                <Typography fontWeight={500} fontSize={12}>
                  NonVeg Only
                </Typography>
              }
            />
          </ButtonGroup>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tables</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={from}
              label="Tables"
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
              onChange={handletableChange}
            >
              <MenuItem value={"POS"}>POS</MenuItem>
              {table.map((item, index) => (
                <MenuItem value={item.name}>
                  Table No:&nbsp;{item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ButtonGroup>
            {/* <Box sx={{ backgroundColor: "#1f1f39", color: "white" }}> */}
            <IconButton
              sx={{ mr: 3 }}
              size="small"
              onClick={toggleDrawer(true)}
            >
              <Badge badgeContent={totalquantity} color="primary">
                <MenuIcon />
              </Badge>
            </IconButton>
            {/* </Box> */}
            {/* <IconButton size="small" onClick={() => setStyleType("list")}>
                <Menu sx={{ color: styleType == "list" ? "#1772ff" : "black" }} />
              </IconButton> */}
          </ButtonGroup>
        </Box>
        <TextField
          className="target-7"
          variant="outlined"
          sx={{
            backgroundColor: "background.paper",
            position: "sticky",
            borderRadius: 30,
            zIndex: 5,
            top: 83,
            [`& fieldset`]: {
              borderRadius: 30,
            },
          }}
          fullWidth
          value={inputvalue}
          onChange={inputHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {inputvalue && (
                  <Clear
                    sx={{ cursor: "pointer" }}
                    onClick={() => setInputvalue("")}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {filterItems.length > 0 ? (
          <>
            {inputvalue && (
              <Typography
                align="left"
                sx={{ ml: 2, position: "relative", top: 10 }}
                fontWeight={800}
              >
                By this keyword ' {inputvalue} ' {filterItems.length}{" "}
                {filterItems.length >= 2 ? "Dishes" : "Dish"}&nbsp; found
              </Typography>
            )}
            <Grid container spacing={2} sx={{ mb: 8.8, p: 1, mt: 1 }}>
              {filterItems.map((item, index) => (
                <Grid item key={item.id} xs={6} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      //   maxWidth: 345,
                      minHeight: 300,
                      width: "100%",
                      borderRadius: 1,
                      p: 1,
                      objectFit: "fill",
                      transform: "scale(1)",
                      transition: " all ease-in 0.2s",
                      backgroundSize: "cover",
                      "&:hover": {
                        transform: "scale(1.01)",
                        filter: `drop-shadow(8px 8px 10px black)`,
                        transition: " all ease-in 0.2s",
                        boxShadow: "9px 9px 31px -18px rgba(0,0,0,0.75)",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={item.name}
                      height="140"
                      sx={{ objectFit: "contain" }}
                      image={`https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${item.id}/${item.img}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
                      }}
                    />
                    <Divider />
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          gutterBottom
                          color="textPrimary"
                          fontWeight={600}
                          variant="body1"
                          component="div"
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            height: 50,
                            //   width: smallscreen ? "10rem" : "18rem",
                            wordWrap: "break-word",
                            WebkitLineClamp: 2,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.name}
                        </Typography>
                        {/* <IconButton
                                  onClick={() => {
                                    handleClickOpen(item.id);
                                  }}
                                >
                                  <Info sx={{ opacity: 0.6 }} />
                                </IconButton> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: "2px",
                          position: "fixed",
                          bottom: 54,
                        }}
                      >
                        <img
                          height={15}
                          src={
                            item.type === "veg"
                              ? "https://banner2.cleanpng.com/20180601/at/kisspng-vegetarian-cuisine-biryani-indian-cuisine-vegetabl-vegetarian-5b11c235a92d48.569689881527890485693.jpg"
                              : "https://banner2.cleanpng.com/20180401/zyq/kisspng-computer-icons-computer-software-light-non-veg-food-5ac181d4128f27.407771801522631124076.jpg"
                          }
                          alt=""
                        />
                        <Typography sx={{ ml: 0.5, fontSize: "12px" }}>
                          {item.type === "veg" ? "Veg" : "Non Veg"}
                        </Typography>
                      </div>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          // width: smallscreen ? "9rem" : "18rem",
                          wordWrap: "break-word",
                          WebkitLineClamp: 1,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: 0.6 }}>
                      {/* <Button size="small">Share</Button> */}
                      {/* <div style={{display:'flex',justifyContent:"space-between",padding:'14px',alignItems:'center'}}> */}
                      <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                        Price:&nbsp;{item.price}Rs
                      </Typography>
                      <Button
                        sx={{
                          fontSize: "12px",
                          borderRadius: 5,
                          ml: smallscreen ? 1 : 5,
                          boxShadow: "6px 6px 10px -7px rgba(0,0,0,0.75)",
                        }}
                        size="3px"
                        color="primary"
                        variant="outlined"
                        onClick={() => addToCart(item)}
                      >
                        <ShoppingBag
                          sx={{
                            fontSize: "14px",
                          }}
                        />
                        Add
                      </Button>
                      {/* </div> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: 60,
              }}
            >
              <img
                style={{}}
                height={300}
                src="https://cdni.iconscout.com/illustration/premium/thumb/error-404-4344461-3613889.png"
                alt=""
              />
              <Typography variant="h3">No Dish Found</Typography>
              <Typography sx={{ mb: 5 }} variant="h6">
                Try something else
              </Typography>
            </div>
          </>
        )}
      </>
      <SwipeableDrawer open={open} anchor="right" onClose={toggleDrawer(false)}>
        {/* {DrawerList} */}
        <div>
          <div style={{ position: "absolute", top: 5, right: 2 }}>
            <IconButton color="error" onClick={toggleDrawer(false)}>
              <Clear />
            </IconButton>
          </div>
          {cartItems.length > 0 ? (
            <>
              {/* <Box
                  sx={{
                    backgroundColor: "alternate.dark",
                  }}
                > */}
              <Typography sx={{ mt: 1 }} variant="h3" align="center">
                Order Summary
              </Typography>

              <List
                sx={{
                  maxHeight:smallscreen?330: 280,
                  height:smallscreen?330: 280,
                  mt: 1,
                  overflow: "auto",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Divider />

                {cartItems.map((item, index) => (
                  <>
                    <ListItem key={index}>
                      {/* <ListItemAvatar> */}
                      <img
                        alt={item.name}
                        src={`https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${item.id}/${item.img}`}
                        style={{
                          height: 80,
                          width: 80,
                          objectFit: "contain",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS7p9zL6f8YFMKjNWlM6QDhTnLi9wk2l7nt7v_5a5ifK85mslB4IcEqMEeA&s";
                        }}
                      />
                      {/* </ListItemAvatar> */}
                      <ListItemText
                        sx={{ ml: 1 }}
                        primary={item.name}
                        secondary={
                          <React.Fragment>
                            <div style={{ display: "flex", padding: 2 }}>
                              <img
                                height={22}
                                src={
                                  item.type == "veg"
                                    ? "https://banner2.cleanpng.com/20180601/at/kisspng-vegetarian-cuisine-biryani-indian-cuisine-vegetabl-vegetarian-5b11c235a92d48.569689881527890485693.jpg"
                                    : "https://banner2.cleanpng.com/20180401/zyq/kisspng-computer-icons-computer-software-light-non-veg-food-5ac181d4128f27.407771801522631124076.jpg"
                                }
                                alt=""
                              />

                              <Typography color="text.primary" sx={{ ml: 0.5 }}>
                                {item.type == "veg" ? "Veg" : "Non Veg"}
                              </Typography>
                            </div>

                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              fontWeight={500}
                            >
                              Price: {item.price}&nbsp;&#8360;
                            </Typography>
                            <IconButton onClick={() => handleMinus(item.id)}>
                              <Remove />
                            </IconButton>

                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {item.quantity}
                            </Typography>
                            <IconButton onClick={() => handlePlus(item.id)}>
                              <Add />
                            </IconButton>
                            {smallscreen && <br />}
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              fontWeight={700}
                            >
                              Total: {item.price * item.quantity} &#8360;
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="error"
                          onClick={() => handleRemove(item)}
                        >
                          <Close />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>

              <Typography variant="h6" marginLeft={2} sx={{ mt: 0.8 }}>
                Add notes:-
              </Typography>
              <div style={{ padding: "10px" }}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Add Notes"
                  multiline
                  placeholder="some changes in dish"
                  rows={2}
                  value={text}
                  onChange={handleText}
                />
              </div>
              <Stack sx={{ px: 3 }} spacing={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography color={"text.secondary"}>Price:</Typography>
                  <Typography color={"text.secondary"} fontWeight={700}>
                    {price1} &#8360;
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography color={"text.secondary"}>Discount:</Typography>
                  <Typography color={"text.secondary"} fontWeight={700}>
                    {/* {discount} */}
                    0&nbsp; &#8360;
                  </Typography>
                </Box>

                {/* <Divider /> */}
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography variant={"h6"} fontWeight={700}>
                    Total Amount:
                  </Typography>
                  <Typography variant={"h6"} fontWeight={700}>
                    {price1} &#8360;
                  </Typography>
                </Box>
                <Divider />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h5">Total: {price1} &#8360;</Typography>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    // style={{ width: "100%" }}
                    startIcon={<ShoppingCart />}
                    onClick={handlePlaceorder}
                  >
                    Place order
                  </Button>
                </Box>
              </Stack>
              {/* <Grid
                  sx={{ mb: 1, ml:smallscreen? 1:3, mt: 3,p:0.5 }}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} lg={6}>
                    <Typography variant="h5">
                      Total: {price1} &#8360;
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      // style={{ width: "100%" }}
                      startIcon={<ShoppingCart />}
                      //   onClick={handlePlaceorder}
                    >
                      Place order
                    </Button>
                  </Grid>
                </Grid> */}
            </>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                padding={5}
                sx={{ mt: "80%" }}
              >
                <img
                  height={120}
                  src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-2130356-1800917.png"
                  alt=""
                />
                <Typography variant="h4" align="center" sx={{ mt: 4 }}>
                  Your Cart is empty
                </Typography>
                <Button
                  onClick={toggleDrawer(false)}
                  sx={{ mt: 3 }}
                  variant="contained"
                >
                  <KeyboardBackspace />
                </Button>
              </Box>
            </>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default Posdashboard;
