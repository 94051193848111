import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import SpecialCard from "./Dashboardcomponent/SpecialCard";
import axios from "axios";
import { Add, BeachAccess, Image, Work } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import toast, { Toaster } from "react-hot-toast";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function AddSpecialMenuItem() {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [menuItems, setMenuitems] = useState([]);
  const [checkoutdata, setCheckoutdata] = useState([]);

  const ShowMenuItems = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/MenuitemList",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setMenuitems(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const data = {
    items: checkoutdata,
  };
    // console.log(data)
  const SubmitData = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/addSpecial",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        handleClose();
        setSelectedProduct([]);
        toast.success(JSON.stringify(response.data), {
          position: "bottom-right",
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        // handleClose()
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  //   console.log(selectedProduct)
  useEffect(() => {
    // Showcategory();
    ShowMenuItems();
  }, []);
  useEffect(() => {
    const data = selectedProduct.map(({ id, name, category_id }) => ({
      id,
      name,

      category_id,
    }));
    setCheckoutdata(data);
  }, [selectedProduct]);
  //   console.log(checkoutdata)
  const handleSelect = (product) => {
    setSelectedProduct((prevselected) => {
      if (prevselected.some((p) => p.id === product.id)) {
        return prevselected.filter((p) => p.id !== product.id);
      } else {
        return [...prevselected, product];
      }
    });
  };

  return (
    <div>
        <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Box>
        <Typography
          variant="h4"
          fontWeight={700}
          align="center"
          sx={{ mt: 2, mb: 2 }}
        >
          Select Item to Add in special menu
        </Typography>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          {menuItems.map((product, index) => (
            <Grid item xs={6} md={4} lg={4}>
              <SpecialCard
                product={product}
                isSelected={selectedProduct.some((p) => p.id === product.id)}
                onSelect={handleSelect}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedProduct.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            // color="warning"
            className="target-1"
            variant="contained"
            sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
            onClick={handleClickOpen}
          >
            <Badge badgeContent={selectedProduct.length} color="error">
              <Add /> Add Menu Items
            </Badge>
          </Button>
        </div>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Special Item List
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              maxHeight: 300,

              overflow: "auto",
            }}
          >
            {selectedProduct.map((list, index) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={list.name}
                    src={`https://simplesoft.co.in/qrmenuapp/uploads/menuitem/${list.id}/${list.img}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={list.name}
                  secondary={
                    <>
                      <Typography>{list.type}</Typography>
                      <Typography>{list.price}</Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={SubmitData}>
            Save changes
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default AddSpecialMenuItem;
