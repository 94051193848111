import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: '40px';
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto ${theme.spacing(2)};

    img {
        width: 60%;
        height: 60%;
        display: block;
    }
`
);

const JsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius:'40px';
    background-color: #fef8d8;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto ${theme.spacing(2)};

    img {
        width: 60%;
        height: 60%;
        display: block;
    }
`
);

function LandingPage() {
  return (
    <>
      {/* <Header /> */}
      {/* <BaseLayout> */}
        <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
          <TypographyH1 sx={{ mb: 2 }} variant="h1">
            DigiMenu
          </TypographyH1>
          {/* <Login/> */}
          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            QR Code Restaurant Menu Maker and Contactless Table Ordering System
            with Restaurant POS
          </TypographyH2>

          <Button
            component={RouterLink}
            to="/dashboards/crypto"
            size="large"
            variant="contained"
          >
            Browse Live Preview
          </Button>

          <Button
            sx={{ ml: 2 }}
            component="a"
            target="_blank"
            rel="noopener"
            href="/"
            size="large"
            variant="text"
          >
            Key Features
          </Button>

          {/* QR Menu Features Section */}
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              Key Features
            </Typography>

            <Grid container spacing={3} mt={5}>
              {/* Feature 1: QR Code Generation */}
              <Grid item md={4}>
                <MuiAvatar>
                  <img
                    src="/static/images/features/qr.svg"
                    alt="QR Code Generation"
                  />
                </MuiAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>QR Code Generation</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Easily generate QR codes for your menu items, allowing
                    customers to access the menu digitally.
                  </Typography>
                </Typography>
              </Grid>

              {/* Feature 2: Contactless Ordering */}
              <Grid item md={4}>
                <JsAvatar>
                  <img
                    src="/static/images/features/qr.svg"
                    alt="Contactless Ordering"
                  />
                </JsAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Contactless Ordering</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Enable contactless ordering and payment through the QR menu,
                    providing a safe and convenient dining experience.
                  </Typography>
                </Typography>
              </Grid>

              {/* Feature 3: Multi-Language Support */}
              <Grid item md={4}>
                <MuiAvatar>
                  <img
                    src="/static/images/features/language.svg"
                    alt="Multi-Language Support"
                  />
                </MuiAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Multi-Language Support</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Offer your menu in multiple languages to accommodate diverse
                    customers and international visitors.
                  </Typography>
                </Typography>
              </Grid>

              {/* Feature 4: Real-Time Updates */}
              <Grid item md={4}>
                <JsAvatar>
                  <img
                    src="/static/images/features/qr.svg"
                    alt="Real-Time Updates"
                  />
                </JsAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Real-Time Updates</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Make instant changes to your menu and see updates reflected
                    in real-time for all customers scanning the QR codes.
                  </Typography>
                </Typography>
              </Grid>

              {/* Feature 5: Analytics Tracking */}
              <Grid item md={4}>
                <MuiAvatar>
                  <img
                    src="/static/images/features/analytics.svg"
                    alt="Analytics Tracking"
                  />
                </MuiAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Analytics Tracking</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Gain insights into customer behavior and preferences with
                    analytics tracking, helping you make informed business
                    decisions.
                  </Typography>
                </Typography>
              </Grid>

              {/* Feature 6: Customizable Design */}
              <Grid item md={4}>
                <JsAvatar>
                  <img
                    src="/static/images/features/design.svg"
                    alt="Customizable Design"
                  />
                </JsAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Customizable Design</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Customize the design and layout of your digital menu to
                    match your brand identity and aesthetic preferences.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>

          {/* Benefits Section */}
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              Benefits
            </Typography>
            <Grid container spacing={3} mt={5}>
              <Grid item md={6}>
                <MuiAvatar>
                  <img
                    src="/static/images/how_it_works/speed.svg"
                    alt="Speed"
                  />
                </MuiAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Speedy Ordering</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Customers can quickly browse the menu, place orders, and pay
                    without waiting for a server.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <JsAvatar>
                  <img
                    src="/static/images/how_it_works/hand.svg"
                    alt="Contactless"
                  />
                </JsAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Contactless Experience</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Ensure safety and hygiene by minimizing physical contact
                    with menus and payment terminals.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>
          {/* How It Works Section */}
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              How It Works
            </Typography>

            <Typography variant="h4" color="text.secondary" sx={{ mt: 3 }}>
              Scan, Order, Enjoy
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={6} md={4}>
                  <MuiAvatar>
                    <img
                      src="/static/images/how_it_works/qr-code.svg"
                      alt="Scan QR"
                    />
                  </MuiAvatar>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Scan the QR Code
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Use your smartphone's camera to scan the QR code located at
                    your table.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MuiAvatar>
                    <img
                      src="/static/images/how_it_works/meal-menu.svg"
                      alt="Browse Menu"
                    />
                  </MuiAvatar>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Browse the Digital Menu
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Explore the digital menu displayed on your phone to view
                    available dishes and drinks.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MuiAvatar>
                    <img
                      src="/static/images/how_it_works/menu-board.svg"
                      alt="Place Order"
                    />
                  </MuiAvatar>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Place Your Order
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Select your desired items and place your order securely
                    online.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MuiAvatar>
                    <img
                      src="/static/images/how_it_works/wait.svg"
                      alt="Wait for Food"
                    />
                  </MuiAvatar>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Wait for Your Food
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Relax as your order is prepared by the kitchen staff and
                    brought to your table.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MuiAvatar>
                    <img
                      src="/static/images/how_it_works/room-service.svg"
                      alt="Enjoy Meal"
                    />
                  </MuiAvatar>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Enjoy Your Meal
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Indulge in delicious food and beverages while enjoying a
                    hassle-free dining experience.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>

          {/* Additional Features Section */}
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              Additional Features
            </Typography>

            <Grid container spacing={3} mt={5}>
              <Grid item md={6}>
                <MuiAvatar>
                  <img src="/static/images/how_it_works/pos.svg" alt="POS" />
                </MuiAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Integrated POS System</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Seamlessly manage orders, payments, and inventory with our
                    integrated Point of Sale (POS) system.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <JsAvatar>
                  <img
                    src="/static/images/how_it_works/design.svg"
                    alt="Customization"
                  />
                </JsAvatar>
                <Typography variant="h4">
                  <Box sx={{ pb: 2 }}>
                    <b>Customizable Menus</b>
                  </Box>
                  <Typography component="span" variant="subtitle2">
                    Easily update and customize your menu items, prices, and
                    descriptions to meet your restaurant's needs.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>

          {/* Call to Action Section */}
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              Ready to get started?
            </Typography>

            <Button
              component={RouterLink}
              to="/register"
              size="large"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Sign Up Now
            </Button>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Already have an account?{" "}
              <RouterLink to="/login" style={{ textDecoration: "none" }}>
                Log in here
              </RouterLink>
            </Typography>
          </Container>
        </Container>
      {/* </BaseLayout> */}
      {/* <Footer /> */}
    </>
  );
}

export default LandingPage;
