import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Facebook,
  Instagram,
  Print,
  QrCode,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import toast, { Toaster } from "react-hot-toast";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import { QRCode } from "react-qrcode-logo";
import ReactToPrint, { useReactToPrint } from "react-to-print";
function TableGenerator() {
  const [table, settable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [hotel, sethotel] = useState("");
  const [preview, setpreview] = useState("");
  const [facebookurl, setfacebookurl] = useState("");
  const [disabled, setDisable] = useState(false);
  const [instagramurl, setinstagramurl] = useState("");
  const [checked, setChecked] = useState(true);
  const [color, setColor] = useState("#000");

  const [style, setStyle] = useState("squares");

  const [name, setName] = useState("");
  const [hid, setHid] = useState("");

  // const [inputvalue, setInputValue] = useState([]);
  // const [qrCodevalue, setqrCodevalue] = useState([]);
  const getHotelid = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/getHotelId",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const Userid = sessionStorage.getItem("userid");
        // console.log(`${response.data}-${Userid}`)
        setHid(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    getHotelid();
  }, []);
  const demo = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/HotelTableList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const sortdata = response.data.sort((a, b) => a.name - b.name);
        console.log(sortdata);
        settable(sortdata);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [Dialogopen, setDialogOpen] = React.useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [deleteName, setDeletename] = useState("");

  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteid("");
    setDeletename("");
  };
  useEffect(() => {
    demo();
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setName("");
  };
  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const handleCloseModal1 = () => {
    setOpenModal1(false);
    setName("");
  };
  const handleAddItem = () => {
    //   if (editMode) {
    //     try {
    //     //   let data = new FormData();
    //     //   data.append("images[]", image);
    //     //   data.append("name", name);
    //       const data = {
    //         name: name,
    //       };
    //       const token = sessionStorage.getItem("Token");
    //       let config = {
    //         method: "post",
    //         maxBodyLength: Infinity,
    //         url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/updateCategories/${editIndex}`,
    //         headers: {
    //           Authorization: `Bearer ${token} `,
    //         },
    //         data: data,
    //       };

    //       axios
    //         .request(config)
    //         .then((response) => {
    //           // console.log(JSON.stringify(response.data));
    //           toast.success(JSON.stringify(response.data), {
    //             position: "bottom-right",
    //             duration: 3000,
    //           });
    //           demo();
    //         })
    //         .catch((error) => {
    //           // console.log(error);
    //         });
    //     } catch (error) {
    //       toast.error("Error updating item:", {
    //         position: "bottom-right",
    //         duration: 3000,
    //       });
    //       console.error("Error updating item:", error);
    //     }
    //   } else {
    try {
      const data = {
        name: name,
      };
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://simplesoft.co.in/qrmenuapp/api/v1/admin/addHotelTable",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          toast.success(JSON.stringify(response.data), {
            position: "bottom-right",
            duration: 3000,
          });
          demo();
          handleCloseModal();
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      toast.error("Error adding item:", {
        position: "bottom-right",
        duration: 3000,
      });
      console.error("Error adding item:", error);
    }
  };
  const handleManyItem = () => {
    //   if (editMode) {
    //     try {
    //     //   let data = new FormData();
    //     //   data.append("images[]", image);
    //     //   data.append("name", name);
    //       const data = {
    //         name: name,
    //       };
    //       const token = sessionStorage.getItem("Token");
    //       let config = {
    //         method: "post",
    //         maxBodyLength: Infinity,
    //         url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/updateCategories/${editIndex}`,
    //         headers: {
    //           Authorization: `Bearer ${token} `,
    //         },
    //         data: data,
    //       };

    //       axios
    //         .request(config)
    //         .then((response) => {
    //           // console.log(JSON.stringify(response.data));
    //           toast.success(JSON.stringify(response.data), {
    //             position: "bottom-right",
    //             duration: 3000,
    //           });
    //           demo();
    //         })
    //         .catch((error) => {
    //           // console.log(error);
    //         });
    //     } catch (error) {
    //       toast.error("Error updating item:", {
    //         position: "bottom-right",
    //         duration: 3000,
    //       });
    //       console.error("Error updating item:", error);
    //     }
    //   } else {
    try {
      //   const data = {
      //     name: name,
      //   };
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/addManyHotelTable/${name}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // data: name,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          toast.success(JSON.stringify(response.data), {
            position: "bottom-right",
            duration: 3000,
          });
          demo();
          handleCloseModal1();
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      toast.error("Error adding item:", {
        position: "bottom-right",
        duration: 3000,
      });
      console.error("Error adding item:", error);
    }
  };
  // };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDeleteItem = () => {
    try {
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrmenuapp/api/v1/admin/deleteHotelTable/${Deleteid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          toast.success(JSON.stringify(response.data), {
            position: "bottom-right",
            duration: 3000,
          });
          demo();
          handleDialogClose();

          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      toast.error("Error deleting item:", {
        position: "bottom-right",
        duration: 3000,
      });
      console.error("Error deleting item:", error);
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setimage(file);
        setpreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const ClearAll = () => {
    sethotel("");
    setinstagramurl("");
    setfacebookurl("");
    setpreview("");
    setDisable(false);
  };
  const handleChange = (event) => {
    setStyle(event.target.value);
  };
  const componentref = useRef();
  const handleprint = useReactToPrint({
    content: () => componentref.current,
  });
  const componentref1 = useRef([]);
  const handleprint1 = useReactToPrint({
    content: () => componentref1.current,
  });
  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3 }}
      >
        <>
          <Grid item lg={12} md={3} xs={12}>
            <Typography align="center" variant="h4">
              Details of Hotel
            </Typography>
          </Grid>

          <Grid item lg={3} md={3} xs={6}>
            <Typography>Hotel Name *</Typography>
            <TextField
              fullWidth
              sx={{ mt: 0.5 }}
              placeholder="Hotel Name"
              variant="outlined"
              value={hotel}
              disabled={disabled}
              onChange={(e) => sethotel(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <Typography>Facebook Url *</Typography>
            <TextField
              fullWidth
              sx={{ mt: 0.5 }}
              placeholder="Facebook Url"
              variant="outlined"
              value={facebookurl}
              disabled={disabled}
              onChange={(e) => setfacebookurl(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <Typography>Instagram Url *</Typography>
            <TextField
              fullWidth
              sx={{ mt: 0.5 }}
              placeholder="Instagram Url"
              variant="outlined"
              disabled={disabled}
              value={instagramurl}
              onChange={(e) => setinstagramurl(e.target.value)}
            />
          </Grid>
          {!preview ? (
            <Grid item xs={6} md={3} lg={3} sx={{ p: 3 }}>
              <Typography>Hotel Logo *</Typography>
              <input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
              />
            </Grid>
          ) : (
            <div>
              <Grid
                item
                xs={6}
                md={3}
                lg={3}
                alignItems="center"
                justifyContent="center"
                sx={{ p: 3 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={preview}
                    alt="Preview"
                    style={{
                      height: "100px",
                      width: "200px",
                      objectFit: "contain",
                    }}
                  />

                  {/* {preview && (
                  <Button onClick={handleClearimage}>Clear Image</Button>
                )} */}
                </div>
              </Grid>
            </div>
          )}

          <Grid item lg={12} md={3} xs={12}>
            <Typography align="center" variant="h4">
              Styling of Qr Code
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Qr Style</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={style}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={"squares"}>Squares</MenuItem>
                <MenuItem value={"dots"}>Dots</MenuItem>
                <MenuItem value={"fluid"}>Fluid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <Typography>Hotel Logo In QR Code</Typography>
            <Checkbox
              checked={checked}
              onChange={handleCheckbox}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <Typography>Color of QR code</Typography>
            <TextField
              fullWidth
              type="color"
              value={color}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setColor(e.target.value)}
            />
          </Grid>
        </>
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button variant="contained" color="error" onClick={ClearAll}>
          RESET
          <Clear />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          position: "fixed",
          top: 100,
          right: 50,
          zIndex: 6,
        }}
      >
        <Button color="primary" onClick={handleprint} variant="contained">
          <Print />
        </Button>
      </div>
      {/* <Grid container spacing={2} sx={{ mb: 7, mt: 1 }}>
        {table.map((item, index) => (
          <Grid key={item.id} item xs={6} md={3} lg={3}>
            <Card
              sx={{
                width: "100%",
                borderRadius: 2,
                transform: "scale(0.9)",
                transition: " all ease-in 0.2s",
                backgroundSize: "cover",
                "&:hover": {
                  // color: "red",
                  transform: "scale(1)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },

                // border: "1px solid black",
              }}
            >
              <QRCode
                value={`https://simplesoft.co.in/qrmenuapp/?hid=${hid}&tid=${item.name}`}
                qrStyle={style}
                logoImage={checked && preview}
                logoOpacity={0.5}
                logoWidth={60}
                logoHeight={60}
                CornerRadii={[40, 50, 20]}
                fgColor={color ? color : "black"}
                size={230}
                ecLevel={"H"}
                logoPaddingStyle={"circle"}
                eyeRadius={[
                  { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                  { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                  { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                ]}
              />
              <CardContent>
                {" "}
                <Typography variant="h5">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <TableRestaurantIcon /> &nbsp;Table No: {item.name}
                    <IconButton
                      color="error"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "all 0.5s",
                        },
                      }}
                      onClick={() => handleDialogOpen(item.id, item.name)}
                    >
                      <Tooltip title="Delete">
                        <DeleteTwoTone />
                      </Tooltip>
                    </IconButton>
                  </div>
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <Grid
        container
        spacing={2}
        sx={{ p: 1, mb: 10 }}
        ref={componentref}
        justifyContent="center"
      >
        {/* <ReactToPrint trigger={()=><Button>Print</Button>} content={()=>componentref1.current}/> */}
        {table?.map((value, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <Grid item xs={6} md={4} lg={3}>
              <Card
                ref={(el) => (componentref1.current[index] = el)}
                sx={{ width: 310, height: 680, mt: 6, p: 0.3 }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IconButton
                    color="error"
                    // sx={{
                    //   "&:hover": {
                    //     transform: "scale(1.1)",
                    //     transition: "all 0.5s",
                    //   },
                    // }}
                    onClick={() => handleDialogOpen(value.id, value.name)}
                  >
                    <Tooltip title="Delete">
                      <Clear />
                    </Tooltip>
                  </IconButton>
                  <ReactToPrint
                    trigger={() => (
                      <IconButton
                        color="primary"
                        // sx={{
                        //   mb: 0.5,
                        //   "&:hover": {
                        //     transform: "scale(1.1)",
                        //     transition: "all 0.5s",
                        //   },
                        // }}
                      >
                        <Print />
                      </IconButton>
                    )}
                    content={() => componentref1.current[index]}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    alt=""
                    height={80}
                    width={80}
                    style={{ objectFit: "contain", marginTop: "10px" }}
                    src={preview}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://cdn.vectorstock.com/i/500p/77/94/your-logo-here-placeholder-symbol-vector-25817794.jpg";
                    }}
                  />
                </div>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    height: 33,
                    // border:'1px solid black',
                    width: "19rem",
                    p: 1,
                    wordWrap: "break-word",
                    WebkitLineClamp: 1,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                  variant="h4"
                  align="center"
                >
                  {hotel}
                </Typography>
                <Typography variant="h1" align="center">
                  SCAN ME
                </Typography>
                <Typography variant="h4" align="center">
                  To View Our Menu
                </Typography>
                <br />
                <div style={{ marginLeft: "25px" }}>
                  <QRCode
                    value={`https://menuapp.blackloop.in/?hid=${hid}&tid=${value.name}`}
                    qrStyle={style}
                    logoImage={checked && preview}
                    logoOpacity={0.5}
                    logoWidth={60}
                    logoHeight={60}
                    CornerRadii={[40, 50, 20]}
                    fgColor={color ? color : "black"}
                    // bgColor={bgcolor ? bgcolor : "white"}
                    size={230}
                    ecLevel={"H"}
                    logoPaddingStyle={"circle"}
                    eyeRadius={[
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                    ]}
                  />
                </div>
                <CardContent>
                  <Typography fontWeight={600} align="center">
                    Table No :{value.name}
                  </Typography>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginBottom: "5px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column",marginRight:'10px' }}>
                      <Facebook sx={{ fontSize: "15px" }} />
                      <Typography fontSize={9} variant="body1" align="center">
                        {facebookurl}
                      </Typography>
                    </div>
                    {/* <hr/> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Instagram sx={{ fontSize: "15px" }} />
                      <Typography fontSize={9} variant="body1" align="center">
                        {instagramurl}
                      </Typography>
                    </div>
                  </div>
                  <Typography variant="subtitle1" align="center">
                    REVIEWS AND COMMENTS ARE APPRECIATED
                  </Typography>
                  <Typography variant="h5" align="center" fontWeight={700}>
                    THANK YOU
                  </Typography>
                </CardContent>
                {/* <IconButton
                  sx={{
                    mb: 0.5,
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  color="primary"
                  onClick={handleprint1}
                >
                  <Print />
                </IconButton> */}
              </Card>
            </Grid>
          </div>
        ))}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          p: 1,
          position: "fixed",
          bottom: 10,
          left: smallscreen ? 5 : 600,
        }}
      >
        <Button
          // color="success"
          variant="contained"
          sx={{ mr: 5 }}
          onClick={handleOpenModal1}
        >
          <Add /> Add Many Table
        </Button>
        <Button
          // color="success"
          variant="contained"
          // sx={{ width: "80%", }}
          onClick={handleOpenModal}
        >
          <Add /> Add Single Table
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
          >{`Are You Sure Delete Table No "${deleteName}" ?`}</Typography>
        </DialogTitle>
        {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
             
            </DialogContentText>
          </DialogContent> */}
        <DialogActions>
          <Button color="error" onClick={() => handleDeleteItem()}>
            Delete
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: 600,
            height: 400,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h5" align="center">
                Add Single Table
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label="table number"
                placeholder="table number"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",

              position: "absolute",
              bottom: 10,
              justifyContent: "space-between",
              left: 120,
            }}
          >
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleAddItem}>
              Add
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={openModal1} onClose={handleCloseModal1}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: 600,
            height: 400,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12} md={12} lg={12}>
              <Typography align="center" variant="h5">
                Add Many Table
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                placeholder="Number of tables"
                label="Number of tables"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",

              position: "absolute",
              bottom: 10,
              justifyContent: "space-between",
              left: 120,
            }}
          >
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleManyItem}>
              Add
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleCloseModal1}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TableGenerator;
